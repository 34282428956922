import { FieldState } from 'final-form';
import { NonFunctionProperties } from 'react-final-form-hooks';

const getStatus = <T = any>(
  meta: NonFunctionProperties<FieldState<T>>,
  validating: boolean,
) => {
  if (meta.active && validating) {
    return 'validating';
  }
  if (!meta.touched) {
    return undefined;
  }
  return meta.error ? 'error' : 'success';
};

export default getStatus;
