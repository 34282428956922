import React from 'react';
import { Link } from 'react-router-dom';

import notFoundPng from '@/assets/404.png';
import createJssHook from '@/utils/jss';

const useStyle = createJssHook({
  notFound: {
    backgroundColor: '#f2f2f2 !important',
    bottom: 0,
    left: 0,
    overflow: 'auto hidden',
    padding: 20,
    position: 'absolute',
    right: 0,
    top: 50,
    '& .panel': {
      backgroundColor: '#fff',
      boxShadow: '0 2px 3px 0 rgba(0,0,0,.2)',
      padding: 20,
      maxWidth: 1360,
      marginLeft: 'auto',
      marginRight: 'auto',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      minHeight: 500,
      '& .unit, & .head': {
        margin: '0 auto',
        textAlign: 'center',
      },
      '& .head i': {
        display: 'inline-block',
        width: 269,
        height: 241,
        backgroundImage: `url(${notFoundPng})`,
        backgroundSize: '269px 241px',
      },
    },
    '& .content': {
      marginTop: 20,
      '& .text': {
        fontSize: 16,
        color: '#444',
        letterSpacing: 0,
        lineHeight: '24px',
      },
      '& .recommend': {
        marginTop: 20,
        fontSize: 0,
        '& .recommendText': {
          fontSize: 12,
          marginRight: 10,
        },
        '& .link': {
          color: '#006eff',
          fontSize: 12,
          letterSpacing: 0,
          lineHeight: '28px',
          margin: '0 20px',
        },
      },
    },
  },
});
const NotFound: React.FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.notFound}>
      <div className='panel'>
        <div className='unit err-404-page'>
          <div className='head'>
            <i></i>
          </div>
          <div className='content'>
            <div className='text'>
              当前页面无法访问，可能由于网址已删除或尚未生效
            </div>
            <div className='recommend'>
              <span className='recommendText'>您可以前往：</span>
              <Link to='/' className='link'>
                首页
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
