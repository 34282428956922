import { Column, Operation, ProTable, ProTableProps } from '@tencent/tea-material-pro-table';
import React from 'react';

import { IReqListQueries } from '@/types/request';
import { useTableColumnSetting } from './utils/hooks';

interface IProps<IRecord = any, IQueries = IReqListQueries>
  extends ProTableProps<IQueries, IRecord> {
  columns: Column[];
  hideExtraOpts?: boolean;
  /** 设置缓存在浏览器中的唯一键后缀，前缀为当前页面的 `pathname`，后缀默认为空，当页面多表格时建议传入进行区分 */
  cacheKeySuffix?: string;
}

const Table = <IRecord, IQueries extends IReqListQueries = any>({
  columns,
  hideExtraOpts,
  cacheKeySuffix,
  ...tableProps
}: IProps<IRecord, IQueries>) => {
  const cacheKey = `${window.location.pathname}${cacheKeySuffix ? `-${cacheKeySuffix}` : ''}`;

  const { columns: nextColumns, operation: settingOpt } = useTableColumnSetting(columns, cacheKey);
  const extraOpts: Operation[] = [settingOpt, { type: 'refresh' }];
  const operations = [...(tableProps.operations || [])];
  if (!hideExtraOpts) {
    operations.push(...extraOpts);
  }

  return (
    <ProTable<IQueries, IRecord>
      pageable
      columns={nextColumns}
      // 先后顺序不同，会决定部分属性是否可被覆盖
      {...tableProps}
      operations={operations}
    />
  );
};

export default Table;
