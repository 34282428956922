import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import Layout from './components/Layout';
import routes from './configs/routes';
import NotFound from './pages/404';
import Login from './pages/Login';
import flattenNestedArr from './utils/flattenNestedArr';

const flattenRoutes = flattenNestedArr(routes, 'routes');
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/login' Component={Login} />
      <Route path='/' Component={Layout}>
        {flattenRoutes.map((route, idx) => {
          const isFirstRoute = idx === 0;
          const routeProps = {
            path: route.path,
            key: route.path,
            exact: route.path === '/',
          };
          const lazyLoadComponent = async () => {
            const { default: Component, ...rest } = await import(`${route.component}`);
            return { Component, ...rest };
          };
          const renderRoute = (props: any) => (
            <Route
              {...routeProps}
              {...props}
              lazy={typeof route.component === 'string' ? lazyLoadComponent : undefined}
              Component={typeof route.component === 'string' ? undefined : route.component}
            />
          );
          return (
            <>
              {isFirstRoute ? renderRoute({ index: true, path: undefined }) : null}
              {renderRoute({})}
            </>
          );
        })}
      </Route>
      <Route path='*' Component={NotFound} />
    </>
  ),
  {
    basename: '/em',
  }
);

const App: React.FC = () => <RouterProvider router={router} />;

export default App;
