import { IResponse } from '@/types/request';
import { ICurrentUser } from '@/types/user';
import request from '@/utils/request';

export const getCurrentUserInfo = async () =>
  request<IResponse<ICurrentUser>>('/user/DescribeUserInfo', {
    method: 'POST',
  });

export const loginUser = async (params: { Name: string; Password: string }) =>
  request<IResponse>('/user/Login', { method: 'POST', data: params });

export const logoutUser = async () =>
  request<IResponse>('/user/Logout', { method: 'POST' });
