import { Icon as TeaIcon, IconProps } from '@tencent/tea-component';
import classNames from 'classnames';
import React from 'react';

import CUSTOM_ICON_MAP from '../constants/customIconMap';
import createJssHook from '../utils/jss';

type ICustomIconTypes = IconProps['type'] | keyof typeof CUSTOM_ICON_MAP;
interface IProps {
  type: ICustomIconTypes;
  className?: string;
  style?: React.CSSProperties;
}

const useStyle = createJssHook<string, { bg?: keyof typeof CUSTOM_ICON_MAP }>({
  icon: (props) => ({
    width: 16,
    height: 16,
    fontSize: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'inhert',
    backgroundImage: props?.bg
      ? `url(${CUSTOM_ICON_MAP[props.bg]})`
      : undefined,
    backgroundSize: '100% 100%',
  }),
});
const Icon: React.FC<IProps> = (props) => {
  const classes = useStyle(
    props.type in CUSTOM_ICON_MAP
      ? { bg: props.type as keyof typeof CUSTOM_ICON_MAP }
      : undefined,
  );

  if (props.type in CUSTOM_ICON_MAP) {
    return (
      <i
        className={classNames(classes.icon, props.className)}
        style={props.style}
      />
    );
  }
  return <TeaIcon {...props} />;
};

export default Icon;
export type { ICustomIconTypes };
