import { Layout as TeaLayout, message } from '@tencent/tea-component';
import React, { useEffect } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';

import { loadUserInfo } from '@/models/currentUser';
import { useStateDispatch } from '@/models/store';
import system from '../configs/system';
import Menu from './Menu';

const { Body, Sider } = TeaLayout;

const Layout: React.FC = () => {
  const navigation = useNavigation();
  const dispatch = useStateDispatch();
  const loadingRef = React.useRef<{ hide: () => void }>();

  useEffect(() => {
    dispatch(loadUserInfo());
  }, [dispatch]);
  useEffect(() => {
    if (navigation.state === 'loading') {
      loadingRef.current = message.loading({ content: '加载中...', duration: 0 });
    } else {
      loadingRef.current?.hide();
    }
  }, [navigation.state]);

  return (
    <TeaLayout>
      <Body>
        {system.showLeftMenu && <Sider>{<Menu />}</Sider>}
        <Outlet />
      </Body>
    </TeaLayout>
  );
};

export default Layout;
