import { Button, Form, Input, message } from '@tencent/tea-component';
import React from 'react';
import { useField, useForm } from 'react-final-form-hooks';

import system from '@/configs/system';
import { loginUser } from '@/services/user';
import getStatus from '@/utils/form';
import createJssHook from '@/utils/jss';
import ls from '@/utils/localStorage';
import sign from '@/utils/sign';
import lockSvg from '../assets/icons/lock.svg';
import userSvg from '../assets/icons/user.svg';
import loginBgPng from '../assets/login-bg.png';
import loginCoverPng from '../assets/login-cover.png';

const useStyle = createJssHook({
  login: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundImage: `url(${loginBgPng})`,
    backgroundSize: 'cover',
    backgroundColor: '#fff',
    overflow: 'auto',
    '& .panel': {
      display: 'flex',
      width: 920,
      height: 550,
      flex: '0 0 content',
      margin: 'auto',
      boxShadow: ' 0px 0px 10px 2px rgba(0, 0, 0, 0.06)',
      '& .left': {
        width: 500,
        height: 551,
        flex: '0 0 auto',
        backgroundImage: `url(${loginCoverPng})`,
      },
      '& .right': {
        flex: '1 1 auto',
        '& header': {
          paddingTop: 97,
          paddingLeft: 40,
          margin: '0 auto',
          '& span': {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: '33.6px',
          },
          '& h1': {
            fontSize: 32,
            lineHeight: '52px',
            fontWeight: 500,
          },
        },
        '& .action': {
          padding: '0 40px',
          border: 0,
          '& button': {
            width: '100%',
            height: 40,
            margin: 0,
            padding: '0 40px',
          },
        },
      },
    },
    '& .username, & .password > input': {
      height: 40,
      padding: '5px 12px 5px 36px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '12px center',
    },
    '& .username': {
      backgroundImage: `url(${userSvg})`,
    },
    '& .password > input': {
      backgroundImage: `url(${lockSvg})`,
    },
    '& b.tea-icon-valid,& b.app-em-icon-valid': {
      marginTop: '12px !important',
    },
    '& .tea-form__help-text,& .app-em-form__help-text': {
      display: 'none !important',
    },
    '& .tea-form__controls,& .app-em-form__controls': {
      paddingRight: 0,
      '& input': {
        width: '100%',
      },
    },
    '& .tea-form,& .app-em-form': {
      width: '100%',
      padding: 40,
      paddingTop: 65,
    },
    '& .tea-input-password,& .app-em-input-password': {
      width: '100% !important',
      '& .tea-input-password__op,& .app-em-input-password__op': {
        top: 6,
      },
    },
  },
});
const Login: React.FC = () => {
  const classes = useStyle();
  const onSubmit = async (values: { username: string; password: string }) => {
    const formData = {
      Name: values.username,
      Password: sign(values.password),
    };
    const resp = await loginUser(formData);
    if (!resp.Response?.Error) {
      message.success({
        content: '登录成功！',
      });
      setTimeout(() => {
        const redirectUrl = ls.get<string>('redirect_url');
        if (redirectUrl) {
          ls.remove('redirect_url');
          window.location.href = redirectUrl;
          return;
        }
        window.location.href = '/em';
      }, 500);
    }
  };
  const { form, handleSubmit, validating, submitting } = useForm({
    onSubmit,
    initialValuesEqual: () => true,
    initialValues: { username: '', password: '' },
    validate: ({ username, password }) => ({
      username: !username ? '请输入账号' : undefined,
      password: !password ? '请输入密码' : undefined,
    }),
  });
  const username = useField('username', form);
  const password = useField('password', form);

  return (
    <div className={classes.login}>
      <div className='panel'>
        <div className='left' />
        <div className='right'>
          <header>
            <span>欢迎使用</span>
            <h1>{system.title}平台</h1>
          </header>
          <form onSubmit={handleSubmit}>
            <Form hideLabel>
              <Form.Item
                status={getStatus(username.meta, validating)}
                message={getStatus(username.meta, validating) === 'error' && username.meta.error}
              >
                <Input
                  {...username.input}
                  className='username'
                  autoComplete='off'
                  placeholder='请输入用户账号'
                />
              </Form.Item>
              <Form.Item
                status={getStatus(password.meta, validating)}
                message={getStatus(password.meta, validating) === 'error' && password.meta.error}
              >
                <Input.Password
                  {...password.input}
                  className='password'
                  autoComplete='off'
                  placeholder='请输入密码'
                  rules={false}
                />
              </Form.Item>
            </Form>
            <Form.Action className='action'>
              <Button
                type='primary'
                htmlType='submit'
                loading={submitting}
                disabled={submitting || !username.input.value || !password.input.value}
              >
                登录
              </Button>
            </Form.Action>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
