import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Checkbox, Modal } from '@tencent/tea-component';
import { isNumber, remove, omit } from 'lodash';

type ModalProps = {
  visible: boolean;
  initValue?: any;
  onDone: (value?: any) => void;
};

const CheckboxGroup = Checkbox.Group;

const toLable = (data: any) => {
  if (data && data.length > 0) {
    return data.map((da: { title: any; key: any; }) => ({ label: da.title, value: da.key }))
  }
  return []
}

const CheckboxCol = (props: any) => {
  const { checkedList, onChange, cd: { key, children } } = props
  const options = toLable(children) // 转换数据格式 { label:'',value:'' }
  // console.log('checkedList', checkedList)
  return <CheckboxGroup value={checkedList[key] || []} onChange={(checkedList) => onChange(checkedList, key)} >
    {options.map((o: any) => <Checkbox key={o.value} name={o.value} >
      {o.label}
    </Checkbox>)}
  </CheckboxGroup>
}

const CheckboxRow = ({ onValueChange, currentValue }: any) => {
  const defalutCheckedList: any = {}

  const weekList = [
    { key: 'Monday', title: '星期一', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Tuesday', title: '星期二', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Wednesday', title: '星期三', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Thursday', title: '星期四', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Friday', title: '星期五', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Saturday', title: '星期六', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
    { key: 'Sunday', title: '星期日', children: new Array(24).fill('').map((val, i) => ({ key: i, title: i })) },
  ].map(item => {
    defalutCheckedList[item.key] = item.children.map(check => check.key)
    return item
  })

  const [checked, setChecked] = useState(true)
  const [checkAll, setCheckAll] = useState<any>({})
  const [checkAll2, setCheckAll2] = useState<any>({})
  const [checkedList, setCheckedList] = useState(defalutCheckedList) // 所有选中数据
  //全部选中
  const onAllChange = (checked: boolean) => {
    let checkData: any = {}
    if (checked === true) {
      weekList.forEach(item => {
        checkData[item.key] = item.children.map(check => check.key)
      })
      setCheckedList({ ...checkedList, ...checkData })
    } else {
      setCheckedList({})
    }
    setChecked(checked)
  }
  // 单选
  const onChange = (checkedkeys: any, parentKey: string) => {
    let checked_list: any = {}
    checked_list[parentKey] = checkedkeys
    // console.log(2, checkedkeys)
    setCheckedList({ ...checkedList, ...checked_list })
  }

  //横向全选
  const onRowChange = (checked: boolean, options: any, parentKey: string) => {
    if (checked) {
      let checked_list: any = {}
      checked_list[parentKey] = options.map((item: { value: any; }) => item.value)
      setCheckedList({ ...checkedList, ...checked_list })
      // console.log(checkAll)
    } else {
      setCheckedList(omit(checkedList, parentKey))
      console.log(omit(checkAll, parentKey))
    }
  }

  //纵向全选
  const onColChange = (checked: boolean, checkedValue: number) => {
    if (checked) {
      let checked_list: any = {}
      weekList.forEach(w => {
        checked_list[w.key] = checkedList[w.key] ? Array.from(new Set([...checkedList[w.key], checkedValue])) : [checkedValue]
      })
      setCheckedList({ ...checkedList, ...checked_list })
    } else {
      let checked_list = JSON.parse(JSON.stringify(checkedList))
      Object.keys(checked_list).forEach(key => {
        remove(checked_list[key], (value) => {
          return value === checkedValue
        })
      })
      setCheckedList(checked_list)
    }
  }

  const handleTime = async () => {
    let list: any = {}
    Object.keys(currentValue).forEach(key => {
      Object.keys(currentValue[key] || {}).forEach(i => {
        list[key] = list[key] || []
        list[key] = currentValue[key][i] ? [...list[key], Number(i)] : [...list[key]]
      })
    })
    setCheckedList(list)
  };

  useEffect(() => {
    onValueChange((checkedList))
    let checked = 0
    let checkedSum: any = {};
    let checkAll: any = {};
    let checkAll2: any = {};
    Object.keys(checkedList).forEach(key => {
      checked = checked + checkedList[key].length
      checkAll[key] = checkedList[key].length === 24
      checkedList[key].forEach((value: string | number) => {
        checkedSum[value] = isNumber(checkedSum[value]) ? checkedSum[value] + 1 : 0
        checkAll2[value] = checkedSum[value] === 6
      });
    })
    // console.log(66, checkedSum)
    setChecked(checked === 168)
    setCheckAll(checkAll)
    setCheckAll2(checkAll2)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList])

  useEffect(() => {
    currentValue && handleTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return <>
    <Row style={{ marginBottom: 10 }}>
      <Col span={2}>
        <Checkbox value={checked} onChange={(e) => onAllChange(e)}>全部选中</Checkbox>
      </Col>
      <Col span={22}>
        {
          new Array(24).fill('').map((val, i) => ({ key: i, title: i })).map(item => {
            return <Checkbox key={item.key} value={checkAll2[item.key]}
              onChange={(e) => onColChange(e, item.key)}
            >
              <span style={{ visibility: 'hidden' }}>{item.title} </span>
            </Checkbox>
          })
        }
      </Col>
    </Row >
    {
      weekList.map(item => (
        <Row style={{ marginBottom: 10 }} key={item.key}>
          <Col span={2}>
            <Checkbox key={item.key} value={checkAll[item.key]}
              onChange={(e) => onRowChange(e, toLable(item.children || []), item.key)}
            >
              {item.title}
            </Checkbox>
          </Col>
          <Col span={22} >
            <div >
              {item && item.children && item.children.length > 0 ? <CheckboxCol key={item.key} cd={item} checkedList={checkedList} onChange={onChange} /> : null}
            </div>
          </Col>
        </Row >
      ))
    }
  </>
}

const MutateTime: FC<ModalProps> = (props) => {
  const { visible, initValue, onDone } = props;
  const [checkedList, setCheckedList] = useState<any>({}) // 所有选中数据

  const onOk = async () => {
    let detail: any = {}
    Object.keys(checkedList).forEach(key => {
      checkedList[key].forEach((val: number) => {
        detail[key] = { ...detail[key], [val]: true }
      })
    })
    // console.log('detail', detail)
    onDone(detail)
  };

  return (
    <Modal
      size={'auto'}
      visible={visible}
      caption={'测绘时间'}
      onClose={() => onDone(false)}
    >
      <Modal.Body style={{ width: 1300 }}>
        <CheckboxRow currentValue={initValue} onValueChange={(value: any) => setCheckedList(value)} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={onOk}>
          确定
        </Button>
        <Button type="weak" onClick={() => onDone(false)}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MutateTime;
