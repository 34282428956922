import enterprise from '@/assets/icons/risk/enterprise.svg'
import domain from '@/assets/icons/risk/domain.svg'
import sub_domain from '@/assets/icons/risk/sub_domain.svg'
import asset from '@/assets/icons/risk/asset.svg'
import port from '@/assets/icons/risk/port.svg'
import http from '@/assets/icons/risk/http.svg'
import app from '@/assets/icons/risk/app.svg'
import wechat_applet from '@/assets/icons/risk/wechat_applet.svg'
import supply_chain from '@/assets/icons/risk/supply_chain.svg'
import manage from '@/assets/icons/risk/manage.svg'
import social_engineering from '@/assets/icons/risk/social_engineering.svg'
import wechat_official_account from '@/assets/icons/risk/wechat_official_account.svg'
import source from '@/assets/icons/source.svg'
import accout from '@/assets/icons/accout.svg'
import vul from '@/assets/icons/risk/vul.svg'
import union from '@/assets/icons/union.svg'
import weak_password from '@/assets/icons/risk/weak_password.svg'
import config from '@/assets/icons/risk/config.svg'
import dark_web from '@/assets/icons/risk/dark_web.svg'
import net_disk from '@/assets/icons/risk/net_disk.svg'
import github from '@/assets/icons/risk/github.svg'
import sensitive_info from '@/assets/icons/risk/sensitive_info.svg'
import IP from '@/assets/icons/IP.svg';


export const RISK_LEVEL_MAP: any = {
    1: '提示',
    2: '低危',
    3: '中危',
    4: '高危',
    5: '严重',
}
export const RISK_LEVEL_COLOR_MAP: any = {
    1: { theme: 'primary' },
    2: { theme: 'warning', color: 'rgb(250, 195, 53)' },
    3: { theme: 'warning', color: 'rgb(244, 165, 71)' },
    4: { theme: 'error', color: '#ff7200' },
    5: { theme: 'error', },

}
export const RISK_MAP: any = {
    'enterprise': {
        apiKey: 'Enterprise',
        moduleKey: '企业架构',
        icon: enterprise,
        fileName: 'import_enterprise',
        columns: [{
            header: '公司名称',
            key: 'Name',
            searchType: "input",
            required: true,
            copyable: true,
            width: 290,
            fixed: "left"
        },
        {
            header: '注册代码',
            key: 'CreditCode',
            searchType: "input",
            // width: 165,
        },
        {
            header: '状态',
            key: 'Status',
            searchType: "input",
            // width: 80,
        },
        {
            header: '注册资金',
            key: 'RegisteredCapital',
            searchType: "input",
            // width: 110,
        },
        {
            header: '控股比例',
            key: 'ShareholdingRatio',
            searchType: "input",
            // width: 80,
        },
        {
            header: '法人',
            key: 'LegalPerson',
            searchType: "input",
            // width: 80,
        },
        {
            header: '类型',
            key: 'Type',
            searchType: "input",
            // width: 160,
        },
        {
            header: '行业类型',
            key: 'Industry',
            searchType: "input",
            // width: 130,
        },]
    },
    'domain': {
        apiKey: 'Domain',
        moduleKey: '主域名',
        icon: domain,
        fileName: 'import_domain',
        columns: [{
            header: '主域名',
            key: 'Domain',
            searchType: "input",
            copyable: true,
            required: true,
            renderLink: true
        },
        {
            header: 'ICP备案号',
            key: 'ICP',
            searchType: "input",
            // width: 170,
        },
        {
            header: '注册时间',
            key: 'RegisteredTime',
            type: 'DatePicker',
            // width: 150,
        },
        {
            header: '过期时间',
            key: 'ExpiredTime',
            type: 'DatePicker',
            // width: 150,
        },
        {
            header: '备案所属公司',
            key: 'Company',
            searchType: "input",
        }
        ]
    },
    'sub_domain': {
        apiKey: 'SubDomain',
        moduleKey: '子域名',
        icon: sub_domain,
        fileName: 'import_sub_domain',
        columns: [{
            header: '子域名',
            key: 'SubDomain',
            required: true,
            copyable: true,
            width: 280,
            renderLink: true,
            searchType: "input",
        },
        {
            header: '解析IP',
            key: 'Ip',
            icon: IP,
            // width: 160,
            searchType: "input",
        },
        {
            header: '国家',
            key: 'Country',
            // width: 110,
            searchType: "input",
        },
        {
            header: '省份',
            key: 'Province',
            // width: 130,
            searchType: "input",
        },
        {
            header: '地区',
            key: 'City',
            // width: 130,
            searchType: "input",
        },
        {
            header: '运营商',
            key: 'Isp',
            // width: 130,
            searchType: "input",
        }
        ]
    },
    'asset': {
        apiKey: 'Asset',
        moduleKey: '主机资产',
        icon: asset,
        fileName: 'import_assets',
        columns: [
            {
                header: '主机类型',
                key: 'Os',
                hideInTable: true
            },
            {
                header: '主机资产',
                key: 'Ip',
                required: true,
                searchType: "input",
            },
            {
                header: '国家',
                key: 'Country',
                searchType: "input",
                // width: 130,
            },
            {
                header: '省份',
                key: 'Province',
                searchType: "input",
                // width: 130,
            },
            {
                header: '地区',
                // width: 140,
                key: 'City',
                searchType: "input",
            },
            {
                header: '运营商',
                key: 'Isp',
                searchType: "input",
            },
        ]
    },
    'port': {
        apiKey: 'Port',
        moduleKey: '端口服务',
        icon: port,
        fileName: 'import_ports',
        columns: [{
            header: '主机地址',
            key: 'Asset',
            required: true,
            searchType: "input",
            copyable: true,
            width: 280
        },
        {
            header: '解析IP',
            key: 'Ip',
            // width: 160,
            icon: IP,
            searchType: "input",
        },
        {
            header: '端口号',
            key: 'Port',
            // width: 100,
            searchType: "input",
            type: 'InputNumber',
        },
        {
            searchType: 'single',
            key: 'IsHighRisk',
            type: 'Select',
            header: '是否高危',
            // width: 100,
            renderTag: true,
            options: [{
                text: '是',
                value: true,
            }, {
                text: '否',
                value: false,
            }],
            values: [
                { key: '1', name: '是' },
                { key: '-1', name: '否' },
            ],
            // render: (record: any) => record.IsHighRisk ? '是' : '否'
        },
        {
            header: '组件',
            key: 'App',
            searchType: "input",
            copyable: true,
        },
        {
            header: '服务',
            key: 'Service',
            searchType: "input",
        },
        {
            header: 'Banner',
            key: 'Banner',
            searchType: "input",
            copyable: true,
        }]
    },
    'http': {
        apiKey: 'Http',
        moduleKey: 'HTTP资产',
        icon: http,
        fileName: 'import_https',
        columns: [{
            header: 'URL',
            key: 'Url',
            required: true,
            copyable: true,
            width: 280,
            searchType: "input",
            renderLink: true
        },
        {
            header: '网站标题',
            key: 'Title',
            searchType: "input",
            // width: 180,
        },
        {
            header: '解析IP',
            key: 'Ip',
            searchType: "input",
            icon: IP,
            // width: 180,
        },
        {
            header: '报文内容',
            key: 'Content',
            hideInTable: true,
            // searchType: "input",
        },
        {
            header: '报文长度',
            key: 'ContentLength',
            type: 'InputNumber',
            searchType: "input",
            hideInTable: true,
        },
        {
            header: '网页截图',
            key: 'ScreenshotUrl',
            searchType: "input",
            isUrl: true,
            // width: 130
        },
        {
            header: '状态码',
            key: 'Code',
            type: 'InputNumber',
            searchType: "input",
            renderDot: true,
            width: 80
        },
        {
            header: '页面类型',
            key: 'Tags',
            searchType: "input",
            isTags: true,
            width: 175,
        },
        {
            header: 'SSL证书过期风险',
            key: 'SslExpiredTime',
            hideInTable: true,
            // searchType: "input",
        }
        ]
    },
    'suspicious_asset': {
        apiKey: 'SuspiciousAsset',
        moduleKey: '可疑资产',
        icon: http,
        fileName: 'import_suspicious_asset',
        columns: [{
            header: 'URL',
            key: 'Url',
            required: true,
            copyable: true,
            width: 280,
            searchType: "input",
            renderLink: true
        },
        {
            header: '网站标题',
            key: 'Title',
            searchType: "input",
        },
        {
            header: '解析IP',
            key: 'Ip',
            searchType: "input",
            icon: IP,
        },
        {
            header: '网页截图',
            key: 'ScreenshotUrl',
            searchType: "input",
            isUrl: true,
            // width: 130
        },
        {
            header: '页面类型',
            key: 'Tags',
            searchType: "input",
            isTags: true,
            width: 175,
        },
        {
            header: '所属者',
            key: 'Owner',
            searchType: "input",
        },
        {
            header: '来源类型',
            key: 'SourceType',
            searchType: "input",
            isBubble: true
        },
        {
            header: '来源值',
            key: 'SourceValue',
            searchType: "input",
            hideInTable: true,
        },
        {
            header: '信任',
            headerTips: '信任后即刻将该资产归入到风险扫描范围内',
            key: 'Trusted',
            type: 'Switch',
            isSwitch: true,
            searchType: "single",
            values: [
                { key: '1', name: '是' },
                { key: '-1', name: '否' },
            ],
        }
        ]
    },
    'app': {
        apiKey: 'App',
        moduleKey: 'APP',
        icon: app,
        fileName: 'import_apps',
        columns: [
            {
                header: '名称',
                key: 'Name',
                required: true,
                width: 250,
                searchType: "input",
            },
            {
                header: '平台',
                key: 'Platform',
                searchType: 'single',
                type: 'Select',
                // width: 100,
                values: [
                    { key: 'ios', name: 'ios' },
                    { key: 'android', name: 'android' },
                ],
                options: [{
                    text: 'ios',
                    value: 'ios',
                }, {
                    text: 'android',
                    value: 'android',
                }],
            },
            {
                header: '图片地址',
                key: 'Logo',
                searchType: "input",
                renderImg: true,
                align: 'center',
                // width: 100,
            },
            {
                header: '版本',
                key: 'Version',
                searchType: "input",
                // width: 100,
            },
            {
                header: '下载链接',
                key: 'DownloadUrl',
                searchType: "input",
            },
            {
                header: '包名',
                key: 'PackageName',
                searchType: "input",
                copyable: true,
            },
            {
                header: '开发者',
                key: 'Developer',
                searchType: "input",
                copyable: true,
            },
            {
                header: '后端地址',
                key: 'ServerUrl',
                searchType: "input",
            },
            {
                header: '描述',
                key: 'Description',
                hideInTable: true,
            },
        ]
    },
    'wechat_applet': {
        apiKey: 'WechatApplet',
        moduleKey: '微信小程序',
        icon: wechat_applet,
        fileName: 'import_wechat_applet',
        columns: [
            {
                header: '名称',
                key: 'Name',
                required: true,
                searchType: "input",
            },
            {
                header: '账号',
                key: 'AccountId',
                searchType: "input",
            },
            {
                header: '图片地址',
                key: 'Logo',
                searchType: "input",
                renderImg: true,
                align: 'center',
            },
            {
                header: '二维码',
                key: 'QrCode',
                searchType: "input",
                align: 'center',
                isUrl: true
            },
            {
                header: '描述',
                key: 'Description',
                hideInTable: true,
            }
        ]
    },
    'wechat_official_account': {
        apiKey: 'WechatOfficialAccount',
        moduleKey: '微信公众号',
        icon: wechat_official_account,
        fileName: 'import_official_account',
        columns: [
            {
                header: '名称',
                key: 'Name',
                required: true,
                searchType: "input",
            },
            {
                header: '账号',
                key: 'AccountId',
                searchType: "input",
            },
            {
                header: '图片地址',
                key: 'Logo',
                searchType: "input",
                renderImg: true,
                align: 'center',
            },
            {
                header: '二维码',
                key: 'QrCode',
                searchType: "input",
                align: 'center',
                isUrl: true
            },
            {
                header: '描述',
                key: 'Description',
                // searchType: "input",
                hideInTable: true,
            }
        ]
    },
    'supply_chain': {
        apiKey: 'SupplyChain',
        moduleKey: '供应链信息',
        fileName: 'import_supply_chain',
        icon: supply_chain,
        columns: [
            {
                header: '供应商',
                key: 'Supplier',
                required: true,
                searchType: "input",
            },
            {
                header: '采购人',
                key: 'Purchaser',
                searchType: "input",
            },
            {
                header: '数据来源',
                key: 'Source',
                searchType: "input",
            },
            {
                header: '采购内容',
                key: 'Content',
                searchType: "input",
            },
        ]
    },
    'manage': {
        apiKey: 'Manage',
        moduleKey: '后台识别',
        icon: manage,
        fileName: 'import_manages',
        columns: [{
            header: 'URL',
            key: 'Url',
            required: true,
            searchType: "input",
            // width: 320,
            copyable: true,
            renderLink: true
        },
        {
            header: '标题',
            key: 'Title',
            searchType: "input",
        },
        // {
        //     header: 'ICON',
        //     key: 'Icon',
        //     align: 'center',
        //     renderImg: true
        // },
        {
            header: '截图',
            key: 'Screenshot',
            isUrl: true,
            // width: 150,
        },
        {
            header: '状态码',
            key: 'Code',
            type: 'InputNumber',
            // width: 100,
            searchType: "input",
            renderDot: true
        }
        ]
    },
    'social_engineering': {
        apiKey: 'SocialEngineering',
        moduleKey: '员工信息',
        icon: social_engineering,
        fileName: 'import_social_engineering',
        columns: [{
            header: '员工姓名',
            required: true,
            key: 'Name',
            searchType: "input",
        },
        {
            header: '邮箱地址',
            key: 'Email',
            searchType: "input",
        },
        {
            header: '历史社工库密码',
            key: 'Password',
            searchType: "input",
        },
        {
            header: '历史社工库md5',
            key: 'PasswordMd5',
            searchType: "input",
            copyable: true,
        },
        {
            header: '历史社工库用户名',
            key: 'HistoryName',
            searchType: "input",
        },
        {
            header: '手机',
            key: 'Phone',
            searchType: "input",
        },
        {
            header: '身份证',
            key: 'IdCard',
            searchType: "input",
        },
        {
            header: '来源',
            key: 'Source',
            searchType: "input",
        },
        ]
    },
    'vul': {
        apiKey: 'Vul',
        fileName: 'import_vuls',
        moduleKey: '漏洞信息',
        icon: vul,
        columns: [
            {
                header: '主机地址',
                key: 'Ip',
                required: true,
                copyable: true,
                width: 220,
                searchType: "input",
            },
            {
                header: '端口',
                key: 'Port',
                required: true,
                // width: 100,
                type: 'InputNumber',
                searchType: "input",
            },
            {
                header: 'URL',
                key: 'Url',
                copyable: true,
                renderLink: true,
                searchType: "input",
            },
            {
                header: '漏洞类型',
                key: 'Type',
                hideInTable: true,
                searchType: "input",
            },
            {
                header: '风险等级',
                key: 'Level',
                type: 'Select',
                searchType: 'single',
                values: [
                    { key: '1', name: '提示' },
                    { key: '2', name: '低危' },
                    { key: '3', name: '中危' },
                    { key: '4', name: '高危' },
                    { key: '5', name: '严重' },
                ],
                options: [{
                    text: '提示',
                    value: 1,
                }, {
                    text: '低危',
                    value: 2,
                }, {
                    text: '中危',
                    value: 3,
                }, {
                    text: '高危',
                    value: 4,
                }, {
                    text: '严重',
                    value: 5,
                }],
                // render: (record: any) => RISK_LEVEL_MAP[record.Level] || '-'
            },
            {
                header: '漏洞名',
                required: true,
                key: 'Name',
                searchType: "input",
                copyable: true,
            },
            // {
            //     header: '状态',
            //     key: 'RepairStatus',
            //     searchType: 'single',
            //     type: 'Select',
            //     // width: 90,
            //     values: [
            //         { key: '1', name: '已修复' },
            //         { key: '0', name: '未修复' },
            //     ],
            //     options: [{
            //         text: '已修复',
            //         value: 1,
            //     }, {
            //         text: '未修复',
            //         value: 0,
            //     }],
            //     render: (record: any) => record.RepairStatus ? '已修复' : '未修复'
            // },
            {
                header: '修复建议',
                key: 'Suggestion',
                copyable: true,
            },]
    },
    'weak_password': {
        apiKey: 'WeakPassword',
        moduleKey: '弱口令',
        fileName: 'import_weak_password',
        icon: weak_password,
        columns: [{
            header: 'IP',
            required: true,
            key: 'Ip',
            width: 250,
            searchType: "input",
            copyable: true,
        },
        {
            header: '端口',
            key: 'Port',
            // width: 80,
            type: 'InputNumber',
            searchType: "input",
        },
        {
            header: 'Host',
            key: 'Url',
            searchType: "input",
            icon: union,
            copyable: true,
        },
        {
            header: '弱口令类型',
            key: 'Type',
            searchType: "input",
            renderTag: true,
            // width: 100,
        },
        {
            header: '弱口令账号',
            key: 'Account',
            icon: accout,
            searchType: "input",
            // width: 100,
        },
        {
            header: '弱口令密码',
            key: 'Password',
            searchType: "input",
            // width: 100,

        }, {
            header: '是否蜜罐',
            key: 'IsHoneypot',
            searchType: "single",
            type: 'Select',
            // width: 80,
            renderTag: true,
            values: [
                { key: '1', name: '是' },
                { key: '-1', name: '否' },
            ],
            options: [{
                text: '是',
                value: true,
            }, {
                text: '否',
                value: false,
            }],
            // render: (record: any) => record.IsHoneypot ? '是' : '否'
        },
        ]
    },
    'config': {
        apiKey: 'Config',
        moduleKey: '目录爆破',
        fileName: 'import_configs',
        icon: config,
        columns: [{
            header: 'URL',
            required: true,
            key: 'Url',
            search: true,
            searchType: "input",
            copyable: true,
            renderLink: true
        },
        {
            header: '标题',
            key: 'Title',
            search: true,
            searchType: "input",
        },
        {
            header: '状态码',
            key: 'Code',
            type: 'InputNumber',
            search: true,
            // width: 100,
            searchType: "input",
            renderDot: true

        },
        {
            header: '响应长度',
            key: 'ContentLength',
            type: 'InputNumber',
            search: true,
            // width: 100,
            searchType: "input",
        }]
    },
    'dark_web': {
        apiKey: 'DarkWeb',
        moduleKey: '暗网泄露',
        fileName: 'import_dark_web',
        icon: dark_web,
        columns: [{
            header: '地址',
            key: 'Url',
            required: true,
            searchType: "input",
        },
        {
            header: '内容',
            key: 'Content',
            searchType: "input",
        },
        {
            header: '命中关键词',
            key: 'MatchedKeywords',
            searchType: "input",
        }]
    },
    'github': {
        apiKey: 'Github',
        moduleKey: 'Github泄露',
        fileName: 'import_githubs',
        icon: github,
        columns: [{
            header: '地址',
            required: true,
            key: 'Url',
            copyable: true,
            searchType: "input",
            // width: 300
        },
        {
            header: '内容',
            key: 'Content',
            searchType: "input",
        },
        {
            header: '命中关键词',
            key: 'MatchedKeywords',
            searchType: "input",
            // width: 120
        },
        {
            header: '状态',
            key: 'Status',
            searchType: "input",
        }]
    },
    'net_disk': {
        apiKey: 'NetDisk',
        moduleKey: '文库网盘泄露',
        fileName: 'import_net_disk',
        icon: net_disk,
        columns: [{
            header: '泄漏地址',
            key: 'Url',
            required: true,
            searchType: "input",
            // width: 220,
            copyable: true,
        },
        {
            header: '命中关键词',
            key: 'MatchedKeywords',
            searchType: "input",
        },
        {
            header: '内容',
            key: 'Content',
            searchType: "input",
        },
        {
            header: '状态',
            key: 'Status',
            searchType: "input",
        },
        {
            header: '泄露平台',
            key: 'Platform',
        },
        ]
    },
    'sensitive_info': {
        apiKey: 'SensitiveInfo',
        moduleKey: '敏感信息泄露',
        fileName: 'import_sensitive_info',
        icon: sensitive_info,
        columns: [{
            header: '值',
            key: 'Value',
            required: true,
            searchType: "input",
            // width: 280,
            copyable: true,
        },
        {
            header: '类型',
            key: 'Type',
            searchType: "input",
            width: 150,
            hideInModal: true,
            renderTag: true
        },
        {
            header: '来源',
            key: 'Source',
            searchType: "input",
            icon: source,
        },
        ]
    },
}