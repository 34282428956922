import { IReqListQueries, IRespList, IResponse } from '@/types/request';
import request from '@/utils/request';
import { IJobRecordDetail, ICreateData, IVulGroup } from '../types';

export const dataApiController = async (queries: any, dataKey: string) =>
  request<IRespList<any>>(`/data/${dataKey}`, {
    method: 'POST',
    data: queries,
  });

export const queryHttpTags = async (queries: any) =>
  request<IRespList<any>>(`/data/DescribeHttpTags`, {
    method: 'POST',
    data: queries,
  });

export const queryHttpCategory = async (queries: any) =>
  request<IRespList<any>>(`/data/DescribeHttpCategory`, {
    method: 'POST',
    data: queries,
  });

export const queryPortComponents = async (queries: any) =>
  request<IRespList<any>>(`/data/DescribePortComponents`, {
    method: 'POST',
    data: queries,
  });

export const importData = async (params: ICreateData) =>
  request<IResponse>(`/data/CreateData`, {
    method: 'POST',
    data: params,
  });

export const getHttpChanges = async (queries: any) =>
  request<IRespList<any>>(`/data/DescribeHttpChanges`, {
    method: 'POST',
    data: queries,
  });

export const ignoreData = async (params: { Ids: number[], Module: string, Ignored: boolean, IsAffectOther?: boolean, IsAggregation?: boolean }) =>
  request<IResponse>(`/data/IgnoreData`, {
    method: 'POST',
    data: params,
  });

export const cancelNewTag = async (params: { Ids: number[], Module: string }) =>
  request<IResponse>(`/data/CancelNewTag`, {
    method: 'POST',
    data: params,
  });

export const getJobRecordDetail = async (module: string, jobRecordId: number, id: number) =>
  request<IRespList<IJobRecordDetail>>(`/data/DescribeJobRecordDetails`, {
    method: 'POST',
    data: {
      Module: module,
      Id: id,
      JobRecordId: jobRecordId
    },
  });

export const exportAllData = (params: any) =>
  request<IResponse>('/data/ExportAllData', {
    method: 'POST',
    data: params,
  });

export const exportDataApiController = async (params: any, dataKey: string) =>
  request<IResponse>(`/data/${dataKey}`, {
    method: 'POST',
    data: params,
  });

export const queryVulGroups = async (queries: IReqListQueries) =>
  request<IRespList<IVulGroup>>('/data/DescribeVulGroups', {
    method: 'POST',
    data: queries,
  });

export const queryPortGroups = async (queries: IReqListQueries) =>
  request<IRespList<IVulGroup>>('/data/DescribePortGroups', {
    method: 'POST',
    data: queries,
  });

export const modifySuspiciousAssetValid = async (params: { CustomerId: number, Ids: number[], Trusted: boolean }) =>
  request<IResponse>('/data/ModifySuspiciousAssetValid', {
    method: 'POST',
    data: params,
  });



