import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCurrentUserInfo, logoutUser } from '@/services/user';
import { ICurrentUser } from '@/types/user';
import { IRootState } from './store';

export interface userState {
  data?: ICurrentUser;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: userState = {
  data: undefined,
  status: 'idle',
};

export const loadUserInfo = createAsyncThunk(
  'user/fetchCurrentUserInfo',
  async () => {
    const resp = await getCurrentUserInfo();
    return resp.Response;
  },
);

export const logoutCurrentUser = createAsyncThunk(
  'user/logoutCurrentUser',
  logoutUser,
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadUserInfo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(loadUserInfo.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(logoutCurrentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutCurrentUser.fulfilled, (state) => {
        state.status = 'idle';
        state.data = undefined;
      })
      .addCase(logoutCurrentUser.rejected, (state) => {
        state.status = 'failed';
      }),
});

export const { clear: clearCurrentUserInfo } = userSlice.actions;

export const selectUserInfo = (state: IRootState) => state.currentUser.data;

export default userSlice.reducer;
