import {
  Alert,
  Button,
  Checkbox,
  Modal,
  TagSearchBoxProps,
  TagValue,
} from '@tencent/tea-component';
import { Column, Operation } from '@tencent/tea-material-pro-table';
import { useEffect, useMemo, useState } from 'react';

import ls from '@/utils/localStorage';

const TableColumnSettingModalChildren: React.FC<{
  cols: any[];
  cacheKey: string;
  onConfirm: (keys: string[]) => void;
}> = ({ cols, cacheKey, onConfirm }) => {
  const [keySelected, setKeySelected] = useState<string[]>([]);
  useEffect(() => {
    const hideColumnKeysCache = ls.get<string[]>(cacheKey) || [];
    setKeySelected(cols.map(col => col.key).filter(key => !hideColumnKeysCache.includes(key)));
  }, [cacheKey, cols]);

  return (
    <>
      <Modal.Body>
        <Alert type='info'>请选择您想显示的列表详细信息。</Alert>
        <Checkbox.Group value={keySelected} onChange={setKeySelected}>
          {cols.map(column => (
            <Checkbox style={{ width: 'calc(33.333% - 18px)' }} key={column.key} name={column.key}>
              {column.header}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button type='primary' onClick={() => onConfirm(keySelected)}>
          确定
        </Button>
      </Modal.Footer>
    </>
  );
};

const useTableColumnSetting = (columns: any[], cacheKeySuffix = '') => {
  const cacheKey = `hideTableColumnKeys${cacheKeySuffix ? `-${cacheKeySuffix}` : ''}`;
  const [hideColumnKeys, setHideColumnKeys] = useState<string[]>();
  const columnsWithDefaultRender = useMemo<any[]>(
    () =>
      columns.map(column => ({
        ...column,
        render: column.render || ((record: any) => record[column.key as keyof any] || '-'),
      })),
    [columns]
  );
  const nextColumns = useMemo(
    () => columnsWithDefaultRender.filter(column => !hideColumnKeys?.includes(column.key)),
    [columnsWithDefaultRender, hideColumnKeys]
  );
  const columnKeys = useMemo(() => columns.map(column => column.key), [columns]);

  const onClick = async () => {
    const modal = Modal.show({
      caption: '自定义列表字段',
      onClose: () => modal.destroy(),
      children: (
        <TableColumnSettingModalChildren
          cols={columns}
          cacheKey={cacheKey}
          onConfirm={keys => {
            modal.destroy();
            setHideColumnKeys(columnKeys.filter(key => !keys.includes(key)));
          }}
        />
      ),
    });
  };
  // const settingOpt: Operation = {
  //   onClick,
  //   type: 'setting',
  //   // style: { marginLeft: 5 },
  // };
  const settingButton = <Button icon="setting" onClick={onClick} />

  useEffect(() => {
    const hideColumnKeysCache = ls.get<string[]>(cacheKey) || [];
    setHideColumnKeys(hideColumnKeysCache);
  }, [cacheKey]);
  useEffect(() => {
    if (!hideColumnKeys) {
      return;
    }
    ls.save(hideColumnKeys).to(cacheKey);
  }, [cacheKey, hideColumnKeys]);

  return { columns: nextColumns, operation: settingButton };
};

const useTableFilterGetResult = (
  tagSearchBoxProps: TagSearchBoxProps,
  tagSearchboxFunction: (arg0: Record<string | number | symbol, any>[]) => void
): Operation => {
  return {
    style: { marginRight: 10 },
    type: 'tagSearchbox',
    ...tagSearchBoxProps,
    onChange: (result: TagValue[]) => {
      // 这里需要封装转换为request参数
      const filterParams: Record<string | number | symbol, any>[] = [];
      result.forEach(item => {
        const params: Record<string | number | symbol, any> = {};
        if (item.attr && item.attr.key) {
          params['Name'] = item.attr.key;
          params['Values'] = [];

          if (item.attr.type === 'input') {
            item.values?.forEach(v => {
              params['Values'].push(v.name);
            });
          } else if (
            item.attr.type === 'single' ||
            item.attr.type === 'multiple' ||
            // 兼容单选带搜索的场景
            (item.attr.type.length > 0 && item.attr.type[0] === 'single')
          ) {
            item.values?.forEach(v => {
              // 特殊处理 CustomerId
              // if (item.attr?.key === 'CustomerId') {
              //   params['Values'].push(parseInt(v.key + '', 10));
              // } else {
              //   params['Values'].push(v.key);
              // }
              params['Values'].push(v.key);
            });
          } else {
            // 暂时未考虑自定义render情况
          }
        } else {
          // 关键词情况
          params['Name'] = 'Keyword';
          params['Values'] = [];
          item.values?.forEach(v => {
            params['Values'].push(v.name);
          });
        }

        filterParams.push(params);
      });
      tagSearchboxFunction(filterParams);
    },
  };
};

export { useTableColumnSetting, useTableFilterGetResult };
