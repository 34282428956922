import { Button, ButtonProps, Checkbox, Justify, StatusTip, Table, message, Modal } from '@tencent/tea-component';
import React, { useState, useRef, useEffect } from 'react';
import { querySeedHistory, deleteSeedHistory, downloadSeed } from '../services/customer';
import { getPageParams } from '@/utils/index';

interface IProps extends ButtonProps {
  customerId: number;
}

const { pageable } = Table.addons;

const MutateBtn: React.FC<IProps> = ({ customerId, ...btnProps }) => {
  const [visible, setVisible] = useState(false);
  const [seedHistory, setSeedHistory] = useState<any>({ List: [], Total: 0 });
  const [relatedSeed, setRelatedSeed] = useState(false);
  const [page, setPage] = useState<{ pageSize: number, pageIndex: number }>({
    pageSize: 20,
    pageIndex: 1,
  });
  const relatedSeedRef: any = useRef();

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };

  const onClose = () => {
    setVisible(false);
  };

  const getSeedHistory = async (pageIndex?: number, pageSize?: number) => {
    const resp = await querySeedHistory({
      CustomerId: customerId,
      ...getPageParams({ pageIndex: pageIndex || page.pageIndex, pageSize: pageSize || page.pageSize }),
    });
    if (!resp.Response?.Error) {
      setSeedHistory(resp.Response)
    }
  };

  const onExport = async (record: any) => {
    const resp: any = await downloadSeed(record.Id)
    if (!resp.Response?.Error) {
      window.open(resp.Response.DownloadLink)
    }
  };

  const onCheckboxChange = (value: boolean) => {
    setRelatedSeed(value)
  };

  const onDelete = async (record: any) => {
    setRelatedSeed(false);
    Modal.confirm({
      message: `确定要删除相关上传种子？`,
      description: <div style={{ marginTop: 10 }}>
        <Checkbox display="block" onChange={onCheckboxChange}>
          <span style={{ verticalAlign: 'top' }}>
            同时删除种子产生的所有数据（包括端口、漏洞数据等）
          </span>
        </Checkbox>
      </div>,
      onOk: async () => {
        const resp = await deleteSeedHistory({ Id: record.Id, IsDeleteRelatedData: relatedSeedRef.current ?? false });
        if (!resp.Response?.Error) {
          message.success({ content: '删除成功！' });
          getSeedHistory();
          return Promise.resolve();
        }
        return Promise.reject();
      },
    });
  };

  useEffect(() => {
    relatedSeedRef.current = relatedSeed;
  }, [relatedSeed]);

  useEffect(() => {
    visible && getSeedHistory();
  }, [visible]);



  return (
    <>
      <Button {...btnProps} onClick={onClick} />
      <Modal visible={visible} onClose={onClose} caption='上传历史'>
        <Table
          bordered={true}
          disableTextOverflow={true}
          compact={true}
          records={seedHistory.List}
          recordKey="Type"
          columns={[
            {
              key: "CreateAt",
              header: "上传时间",
            },
            {
              key: "Count",
              header: "种子数量",
            },
            {
              key: 'Action',
              header: '操作',
              width: 100,
              render: record => {
                return (
                  <Justify
                    left={
                      <>
                        <Button type='link' onClick={() => onExport(record)}>
                          下载
                        </Button>
                        <Button type='link' onClick={() => onDelete(record)}>
                          删除
                        </Button>
                      </>
                    }
                  />
                );
              },
            },
          ]}
          addons={[pageable({
            recordCount: seedHistory?.Total || 0,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize,
            onPagingChange(query: any) {
              setPage((v) => ({ ...v, ...query }))
              getSeedHistory(query.pageIndex, query.pageSize)
            },
          })]}
          topTip={
            !seedHistory.Total && (
              <StatusTip
                // @ts-ignore
                status={'empty'}
              />
            )
          }
        />
      </Modal>
    </>
  );
};

export default MutateBtn;
