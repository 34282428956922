/**
 * 字符串是否是一个有效的 HTTP URL
 * @param string 目标字符串
 */

export const isValidHttpUrl = (string: string) => {
    try {
        const newUrl = new URL(string);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
        return false;
    }
}