import { Avatar, Dropdown, List } from '@tencent/tea-component';
import React from 'react';

import { logoutCurrentUser, selectUserInfo } from '@/models/currentUser';
import { useStateDispatch, useStateSelector } from '@/models/store';

const AvatarDropdown: React.FC = () => {
  const dispatch = useStateDispatch();
  const currentUserInfo = useStateSelector(selectUserInfo);

  if (!currentUserInfo?.Name) {
    return null;
  }
  return (
    <Dropdown
      trigger='hover'
      button={
        <Avatar
          width={30}
          height={30}
          color={Avatar.Color.Blue}
          text={currentUserInfo.NickName?.charAt(0) || currentUserInfo.Name.charAt(0)}
        />
      }
    >
      {/* {(close) => ( */}
      <List type='option'>
        <List.Item
          onClick={() => {
            dispatch(logoutCurrentUser());
          }}
        >
          退出登录
        </List.Item>
      </List>
      {/* )} */}
    </Dropdown>
  );
};

export default AvatarDropdown;
