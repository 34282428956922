/**
 * 将嵌套数组扁平化，返回扁平化后的数组，如：
 * const arr = [{ id: 1, children: [{ id: 2 }] }];
 * flattenNestedArr(arr, 'children') => [{ id: 1 }, { id: 2 }]
 * @param arr 目标数组
 * @param nextedKey 扁平化的 key
 * @returns arr
 */
const flattenNestedArr = <T = any>(arr: T[], nextedKey: keyof T): T[] => {
  const result: T[] = [];
  arr.forEach((a) => {
    if (a[nextedKey]) {
      result.push(...flattenNestedArr(a[nextedKey] as T[], nextedKey));
    } else {
      result.push(a);
    }
  });
  return result;
};

export default flattenNestedArr;
