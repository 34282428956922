import JSEncrypt from 'jsencrypt';

const encryptInst = new JSEncrypt({});

encryptInst.setPublicKey(`-----BEGIN RSA PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2N7M1s19p+1zV3Q0k+u4
YM1gduXp0wn7OdFt4BsAetNQL8a8O4g3RRLEGAOI+UY31V6Vig9qj8JaveltmnkI
jcYvcjNON1zeQD1HHjLS//8TU+MhDWNCZo6tmSmGk+rtcz0DtiG2Z7nTat53vjyF
2Gx7YuxWCSQOVyx2ljTVHsoUsM6Z6iZlFj9RXffmcm3YU8z56vpDy542UfzY5AFg
E+yt0hZzcjrNlp2A/NVntgdT7TNglWIA/2zFzOMQVm9t1D6JB34O1oei07zVxaRF
VwxxjzkqfdIYWyFvqDyamANhS/4GOXk7YNNYTtGP5YWWHsAawpNalZ0dtANWiiol
gwIDAQAB
-----END RSA PUBLIC KEY-----`);

const sign = (data: string) => encryptInst.encrypt(data) || 'encrypt failed';

export default sign;
