import jss, { Styles, StyleSheetFactoryOptions } from 'jss';
import jssPreset from 'jss-preset-default';
import { createUseStyles } from 'react-jss';

jss.setup(jssPreset());

// deprecated
// const createJss = (
//   styles: Partial<Styles>,
//   options?: StyleSheetFactoryOptions
// ) => jss.createStyleSheet(styles, options).attach();
const createJssHook = <
  IName extends string | number | symbol = string,
  IProps = unknown,
  ITheme = undefined,
>(
  styles: Styles<IName, IProps, ITheme>,
  options?: StyleSheetFactoryOptions,
) => createUseStyles(styles, options);

// export default createJss;
export default createJssHook;
