import { Menu as TeaMenu } from '@tencent/tea-component';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import routes from '@/configs/routes';
import system from '@/configs/system';
import { IAppRoute } from '@/types/app';
import createJssHook from '@/utils/jss';
import Icon from './Icon';

interface ILinkProps {
  route: string;
  title: string;
  icon?: React.ReactNode;
  selected?: boolean;
  onActive: (props: { isActive: boolean; isPending: boolean; isTransitioning: boolean }) => string;
}

const useStyle = createJssHook({
  menuLink: {
    textDecoration: 'none !important',
    '& .icon': {
      marginTop: 5,
      marginRight: 8,
    },
    '& .is-selected': {
      '& > .menu-item': {
        backgroundColor: '#006eff !important',
        '& .icon': {
          filter: 'brightness(10)',
        },
      },
    },
  },
});

const Link: React.FC<ILinkProps> = props => (
  <NavLink to={props.route} className={props.onActive}>
    <TeaMenu.Item
      title={props.title}
      icon={props.icon}
      selected={props.selected}
      render={children => <span className='menu-item'>{children}</span>}
    />
  </NavLink>
);

const Menu: React.FC = () => {
  const classes = useStyle();
  const firstRoute = routes.find(item => !item.hideInMenu && item.path);
  const [currentRoute, setCurrentRoute] = useState<IAppRoute>();
  const sysIcon = system.icon ? (
    <Icon className='tea-menu__title-icon' type={system.icon} style={{ width: 32, height: 32 }} />
  ) : undefined;

  useEffect(() => {
    const platformName = window.msp.em?.platformName || '';
    document.title =
      currentRoute?.path === '/'
        ? `${system.title} - ${platformName}`
        : currentRoute?.name
        ? `${currentRoute.name} - ${system.title}${platformName ? ` - ${platformName}` : ''}`
        : system.title;
  }, [currentRoute]);
  useEffect(() => {
    if (window.location.pathname === '/' || /\/\w+(\/)?/.test(window.location.pathname)) {
      setCurrentRoute(firstRoute);
    }
  }, [firstRoute]);

  return (
    <TeaMenu
      theme={system.theme as any}
      title={system.title}
      icon={sysIcon}
      collapsable={!!system.icon}
    >
      {routes.map((item, index) => {
        if (item.hideInMenu) {
          return null;
        }
        const icon = item.icon ? (
          typeof item.icon === 'string' ? (
            <Icon className='icon' type={item.icon} />
          ) : (
            item.icon
          )
        ) : undefined;
        if (item.showAsDivider) {
          return <TeaMenu.Group key={index} title={item.name} />;
        }
        if (item.routes) {
          return (
            <TeaMenu.SubMenu
              defaultOpened
              key={index}
              title={item.name}
              className={classes.menuLink}
              icon={icon}
            >
              {item.routes.map((subItem, index) =>
                subItem.hideInMenu ? null : (
                  <Link
                    key={index}
                    route={subItem.path || ''}
                    title={subItem.name || ''}
                    selected={currentRoute?.path === subItem.path}
                    onActive={({ isActive }) => {
                      isActive && subItem.path && setCurrentRoute(subItem);
                      return classes.menuLink;
                    }}
                  />
                )
              )}
            </TeaMenu.SubMenu>
          );
        }
        return (
          <Link
            key={index}
            icon={icon}
            route={item.path || ''}
            title={item.name || ''}
            selected={currentRoute?.path === item.path}
            onActive={({ isActive }) => {
              isActive && item.path && setCurrentRoute(item);
              return classes.menuLink;
            }}
          />
        );
      })}
    </TeaMenu>
  );
};
export default Menu;
