import { Button, ButtonProps, Input, Form, message, Modal } from '@tencent/tea-component';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';

import getStatus from '@/utils/form';
import createJssHook from '@/utils/jss';
import { modifySeed } from '../services/customer';
import { ISeed } from '../types';

interface IProps extends ButtonProps {
  record: ISeed;
  onSuccess?: () => void;
}

const { Item } = Form;
const useStyle = createJssHook({
  form: {
  },

});
const MutateBtn: React.FC<IProps> = ({ record, onSuccess, ...btnProps }) => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };

  const onSubmit = async (
    formData: Partial<any>,
    formInst: FormApi
  ) => {
    const resp = await modifySeed({
      Value: formData.Value,
      Id: record.Id
    })
    if (!resp.Response?.Error) {
      message.success({
        content: '操作成功！',
      });
      setVisible(false);
      formInst.restart();
      onSuccess?.();
    }
  };
  const { form, handleSubmit, validating, submitting } = useForm<Partial<Partial<any>>>({
    onSubmit,
    initialValuesEqual: () => true,
    validate: formData => {
      return ({
        Value: !formData.Value ? '请输入' : undefined,
      })
    },
  });

  const nameField = useField('Value', form);

  const onClose = () => {
    setVisible(false);
    form.restart();
  };

  useEffect(() => {
    if (visible) {
      form.initialize({
        ...record,
      });
    } else if (!visible) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Button {...btnProps} onClick={onClick} />
      <Modal visible={visible} caption={'编辑'} size={'s'} onClose={onClose}>
        <form onSubmit={handleSubmit} >
          <Modal.Body>
            <Form className={classes.form}>
              <Item
                required
                label='值'
                status={getStatus(nameField.meta, validating)}
                message={getStatus(nameField.meta, validating) === 'error' && nameField.meta.error}
              >
                <Input
                  {...nameField.input}
                />
              </Item>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type='primary' loading={submitting} disabled={submitting}>
              确定
            </Button>
            <Button type="weak" htmlType='button' onClick={onClose}>
              取消
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default MutateBtn;
