import { Button, ButtonProps, Input, Checkbox, Justify, StatusTip, Table, Tabs, TabPanel, Upload, RadioGroup, Radio, Form, message, Modal } from '@tencent/tea-component';
import { FormApi } from 'final-form';
import React, { useState, useRef, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';

import getStatus from '@/utils/form';
import createJssHook from '@/utils/jss';
import { importSeeds, createSeeds, querySeedHistory, deleteSeedHistory, downloadSeed } from '../services/customer';
import { FILE_UPLOAD_URL } from '@/constants/common';
import { getPageParams } from '@/utils/index';

interface IProps extends ButtonProps {
  customerId: number;
  customerName: string;
  onSuccess?: () => void;
}

const { Item } = Form;
const { pageable } = Table.addons;

const tabs = [
  { id: "upload", label: "上传种子" },
  { id: "history", label: "上传历史" },
];
const useStyle = createJssHook({
  form: {
    '& .tea-input, & .tea-dropdown, & .tea-textarea': {
      width: '100%',
    },
    '& .app-em-input, & .app-em-dropdown, & .app-em-textarea': {
      width: '100%',
    },
    '& .tea-form-upload__thumb,& .app-em-form-upload__thumb': {
      width: 70,
      height: 70
    },
    '& .tea-form-upload__info,& .app-em-form-upload__info': {
      height: 70
    }
  },
  tabs: {
    marginTop: -30
  },
  seedModal: {
    "& .tea-btn": {
      zIndex: 999
    }
  }

});
const MutateBtn: React.FC<IProps> = ({ customerId, customerName, onSuccess, ...btnProps }) => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [seedHistory, setSeedHistory] = useState<any>({ List: [], Total: 0 });
  const [relatedSeed, setRelatedSeed] = useState(false);
  const [page, setPage] = useState<{ pageSize: number, pageIndex: number }>({
    pageSize: 20,
    pageIndex: 1,
  });
  const relatedSeedRef: any = useRef();

  const xhrRef: any = useRef(null);

  function handleStart(file: any, { xhr }: any) {
    setFile(file);
    xhrRef.current = xhr;
  }

  function handleSuccess(result: any) {
    // console.log(result);
    form.change('FileId', result.Response.FileIdList[0])
  }

  function handleError() {
    handleAbort();
    Modal.alert({
      type: "error",
      message: "错误",
      description: "请求服务器失败",
    });
  }

  function handleAbort() {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
    setFile(null);
    form.change('FileId', undefined)
  }

  function parseString(string: string | null): string[] {
    if (!string) return [];
    // 根据不同的分隔符进行分割
    if (string.includes('\n')) {
      return string.split('\n').map(ip => ip.trim());
    } else if (string.includes(';')) {
      return string.split(';').map(ip => ip.trim());
    } else if (string.includes('，')) {
      return string.split('，').map(ip => ip.trim());
    } else {
      return string.split(',').map(ip => ip.trim());
    }
  }

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };


  const onSubmit = async (
    formData: Partial<any>,
    formInst: FormApi
  ) => {
    const FormData = {
      ...formData,
      CustomerId: customerId,
    } as any;
    delete FormData.Way
    delete FormData.Icon
    const resp = formData.Way === 'input' ?
      await createSeeds({
        ...FormData,
        Ips: parseString(formData.Ips),
        Titles: parseString(formData.Titles),
        Domains: parseString(formData.Domains),
        Icons: parseString(formData.Icon),
      })
      : await importSeeds({ ...FormData, FileId: formData.FileId })
    if (!resp.Response?.Error) {
      message.success({
        content: '新增成功！',
      });
      setVisible(false);
      setFile(null)
      formInst.restart();
      onSuccess?.();
    }
  };
  const { form, handleSubmit, validating, submitting } = useForm<Partial<Partial<any>>>({
    onSubmit,
    initialValuesEqual: () => true,
    initialValues: {
      Way: 'input'
    },
    validate: formData => {
      return ({
        FileId: formData.Way === 'upload' && !formData.FileId ? '请选择文件' : undefined,
      })
    },
  });
  const customerIdField = useField('CustomerId', form);
  const IpsField = useField('Ips', form);
  const fileIdField = useField('FileId', form);
  const IconField = useField('Icon', form);
  const wayField = useField('Way', form);
  const DomainsField = useField('Domains', form);
  const TitlesField = useField('Titles', form);

  const onClose = () => {
    setVisible(false);
    setFile('')
    form.restart();
  };

  const getSeedHistory = async (pageIndex?: number, pageSize?: number) => {
    const resp = await querySeedHistory({
      CustomerId: customerId,
      ...getPageParams({ pageIndex: pageIndex || page.pageIndex, pageSize: pageSize || page.pageSize }),
    });
    if (!resp.Response?.Error) {
      setSeedHistory(resp.Response)
    }
  };

  const onExport = async (record: any) => {
    const resp: any = await downloadSeed(record.Id)
    if (!resp.Response?.Error) {
      window.open(resp.Response.DownloadLink)
    }
  };

  const onCheckboxChange = (value: boolean) => {
    setRelatedSeed(value)
  };

  const onDelete = async (record: any) => {
    setRelatedSeed(false);
    Modal.confirm({
      message: `确定要删除相关上传种子？`,
      description: <div style={{ marginTop: 10 }}>
        <Checkbox display="block" onChange={onCheckboxChange}>
          <span style={{ verticalAlign: 'top' }}>
            同时删除种子产生的所有数据（包括端口、漏洞数据等）
          </span>
        </Checkbox>
      </div>,
      onOk: async () => {
        const resp = await deleteSeedHistory({ Id: record.Id, IsDeleteRelatedData: relatedSeedRef.current ?? false });
        if (!resp.Response?.Error) {
          message.success({ content: '删除成功！' });
          getSeedHistory();
          return Promise.resolve();
        }
        return Promise.reject();
      },
    });
  };

  useEffect(() => {
    relatedSeedRef.current = relatedSeed;
  }, [relatedSeed]);

  return (
    <>
      <Button {...btnProps} onClick={onClick} />
      <Modal visible={visible} onClose={onClose} className={classes.seedModal}>
        <Tabs className={classes.tabs} tabs={tabs} onActive={(item) => {
          item.id === 'history' && getSeedHistory()
        }}>
          <TabPanel id="upload">
            <form onSubmit={handleSubmit} >
              <Modal.Body>
                <Form className={classes.form}>
                  <Item
                    required
                    label='方式'
                  >
                    <RadioGroup  {...wayField.input}>
                      <Radio name="input">手工录入</Radio>
                      <Radio name="upload">批量导入</Radio>
                    </RadioGroup>
                  </Item>
                  <Item required label='企业'>
                    <Form.Text {...customerIdField.input}>{customerName || '-'}</Form.Text>
                  </Item>
                  {
                    wayField.input.value === 'input' ? <>
                      <Item
                        label='IP'
                      >
                        <Input.TextArea
                          {...IpsField.input}
                          placeholder='多个IP可用换行、分号或逗号分隔'
                        />
                      </Item>
                      <Item
                        label='域名'
                      >
                        <Input.TextArea
                          {...DomainsField.input}
                          placeholder='多个域名可用换行、分号或逗号分隔'
                        />
                      </Item>
                      <Item
                        label='图标'
                      >
                        <Input.TextArea
                          {...IconField.input}
                          placeholder='多个图标可用换行、分号或逗号分隔'
                        />
                      </Item>
                      <Item
                        label='标题'
                      >
                        <Input.TextArea
                          {...TitlesField.input}
                          placeholder='多个标题可用换行、分号或逗号分隔'
                        />
                      </Item>
                    </> : <Item
                      label='文件'
                      required
                      status={getStatus(fileIdField.meta, validating)}
                      message={<div>
                        下载{' '}
                        <a
                          target="_blank"
                          download="新增数据示例模版.xlsx"
                          href={`/em/files/tencent_em.xlsx`} rel="noreferrer"
                        >
                          新增数据示例模版
                        </a>{' '}
                        填写之后上传文档即可
                      </div>}
                    >
                      <Upload
                        {...fileIdField.input}
                        withCredentials
                        action={FILE_UPLOAD_URL}
                        name='File'
                        accept=".xlsx"
                        onStart={handleStart}
                        onSuccess={handleSuccess}
                        onError={handleError}
                      >
                        {({ open }) => (
                          <Upload.File
                            filename={file && file.name}
                            button={
                              getStatus(fileIdField.meta, validating) === "validating" ? (
                                <Button htmlType='button' onClick={handleAbort}>取消上传</Button>
                              ) : (
                                <>
                                  <Button htmlType='button' onClick={open}>
                                    {"选择文件"}
                                  </Button>
                                  {fileIdField.input.value && (
                                    <Button
                                      type="link"
                                      htmlType='button'
                                      style={{ marginLeft: 8 }}
                                      onClick={handleAbort}
                                    >
                                      删除
                                    </Button>
                                  )}
                                </>
                              )
                            }
                          />
                        )}
                      </Upload>
                    </Item>
                  }
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button type='primary' loading={submitting} disabled={submitting}>
                  确定
                </Button>
                <Button type="weak" htmlType='button' onClick={onClose}>
                  取消
                </Button>
              </Modal.Footer>
            </form>
          </TabPanel>
          <TabPanel id="history">
            <Table
              bordered={true}
              disableTextOverflow={true}
              compact={true}
              records={seedHistory.List}
              recordKey="Type"
              columns={[
                {
                  key: "CreateAt",
                  header: "上传时间",
                },
                {
                  key: "Count",
                  header: "种子数量",
                },
                {
                  key: 'Action',
                  header: '操作',
                  width: 100,
                  render: record => {
                    return (
                      <Justify
                        left={
                          <>
                            <Button type='link' onClick={() => onExport(record)}>
                              下载
                            </Button>
                            <Button type='link' onClick={() => onDelete(record)}>
                              删除
                            </Button>
                          </>
                        }
                      />
                    );
                  },
                },
              ]}
              addons={[pageable({
                recordCount: seedHistory?.Total || 0,
                pageIndex: page.pageIndex,
                pageSize: page.pageSize,
                onPagingChange(query: any) {
                  setPage((v) => ({ ...v, ...query }))
                  getSeedHistory(query.pageIndex, query.pageSize)
                },
              })]}
              topTip={
                !seedHistory.Total && (
                  <StatusTip
                    // @ts-ignore
                    status={'empty'}
                  />
                )
              }
            />
          </TabPanel>
        </Tabs>
      </Modal>
    </>
  );
};

export default MutateBtn;
