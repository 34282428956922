import { notification } from '@tencent/tea-component';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { isValid } from 'date-fns';
import React from 'react';

import { IRespList } from '@/types/request';
import ls from './localStorage';

interface IRequest {
  <T = any, D = any>(config: AxiosRequestConfig<D>): Promise<T>;
  <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
}

const codeMessage: { [key: string]: string } = {
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
const apiCodeMessage: { [key: string]: string } = {
  IoaLoginVerification: '身份凭证失效，请重新登录',
  UserLoginVerification: '身份凭证失效，请重新登录',
  'RequestError.ParamsInvalid': '参数错误',
  'InternalError.PermissionDenied': '无操作权限',
  InternalError: '服务器内部错误',
};

let API_BASE_URL: string;
if (window.msp.em.apiBaseUrl) {
  API_BASE_URL = window.msp.em.apiBaseUrl;
} else {
  API_BASE_URL = '/v3/';
}

const axiosRequest = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

axiosRequest.interceptors.response.use(
  async resp => {
    const { url, responseType } = resp.config;
    if (responseType === 'blob') {
      return resp;
    }
    const data = resp.data;
    if (['WxworkLoginVerification', 'IoaLoginVerification'].includes(data.Response.Error?.Code)) {
      ls.save(window.location.href).to('redirect_url');
      // window.location.href = data.Response.Error.Message;
      throw resp;
    }
    if (data.Response.Error?.Code === 'UserLoginVerification') {
      ls.save(window.location.href).to('redirect_url');
      // navigate('/login');
      window.location.href = '/em/login';
      throw resp;
    }
    if (data.Response.Error?.Code === 'InvalidLicense') {
      //前往导入许可证  
      window.location.href = '/em/overview?type=license';
    }
    // 用户未登录 / 登录态失效不展示错误提示
    if (data.Response.Error && !url?.includes('DescribeUserInfo')) {
      notification.error({
        title: '请求错误',
        description: React.createElement('div', null, [
          React.createElement('div', { key: 'url' }, url),
          React.createElement(
            'div',
            { key: 'msg', className: 'mt-5' },
            `${apiCodeMessage[data.Response.Error.Code] || ''}[${data.Response.Error.Message}]` ||
            `未知错误（${data.Response.Error.Code}）`
          ),
        ]),
      });
    }
    return resp;
  },
  (error: AxiosError) => {
    if (error.response && codeMessage[error.response.status]) {
      notification.error({
        title: `服务器异常 [${error.response.status}] ${error.response.statusText}`,
        // description: `${new URL(url).pathname}\n${codeMessage[error.status]}`,
        description: [
          React.createElement('div', { key: 'url' }, error.config?.url),
          React.createElement('div', { key: 'msg' }, codeMessage[error.response.status]),
        ],
      });
    }
    throw error;
  }
);

const request: IRequest = async (url, config: AxiosRequestConfig = {}) => {
  if (typeof url === 'string') {
    config = config || {};
    config.url = url;
  } else {
    config = url || {};
  }
  const resp = await axiosRequest(config);
  return resp.data;
};

/**
 * 适配 pro table request 参数的请求封装
 * @param requestFunc 请求方法
 */
const tableDataReqGen =
  <U, T>(requestFunc: (params: T) => Promise<IRespList<U>>) =>
    async (params: T & { pageSize?: number; current?: number;[key: string]: any }) => {
      const { pageSize, current, pageIndex, ...restParams } = params;
      delete restParams._timestamp;
      const newParams: any = {
        ...restParams,
        Limit: pageSize || 10,
        Offset: (pageSize || 10) * ((current || 1) - 1),
      };
      /** 自动转换时间区间的筛选参数 */
      Object.keys(newParams).forEach(paramKey => {
        const paramVal = newParams[paramKey] as string;
        const dateRegx = /^\d{4}(-\d{2}){2}/;
        if (
          Array.isArray(paramVal) &&
          paramVal.length === 2 &&
          isValid(new Date(paramVal[0])) &&
          isValid(new Date(paramVal[1])) &&
          dateRegx.test(paramVal[0]) &&
          dateRegx.test(paramVal[1])
        ) {
          const paramKeys = paramKey.split(',');
          if (paramKeys.length > 1) {
            newParams[paramKeys[0]] = paramVal[0];
            newParams[paramKeys[1]] = paramVal[1];
          } else {
            newParams.StartAt = paramVal[0];
            newParams.EndAt = paramVal[1];
          }
          delete newParams[paramKey];
        }
      });
      const resp = await requestFunc(newParams);
      return {
        data: resp.Response?.List?.map((v, idx) => ({ ...v, Number: idx + 1 })) || [],
        total: resp.Response?.Total || 0,
        success: !resp.Response?.Error,
        originalData: resp,
      };
    };

export default request;
export { API_BASE_URL, tableDataReqGen };
