import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import currentUser from './currentUser';

export type IStateDispatch = typeof store.dispatch;
export type IRootState = ReturnType<typeof store.getState>;
export type IStateThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IRootState,
  unknown,
  Action<string>
>;

export const store = configureStore({
  reducer: {
    currentUser,
  },
});

export const useStateDispatch = () => useDispatch<IStateDispatch>();
export const useStateSelector: TypedUseSelectorHook<IRootState> = useSelector;
