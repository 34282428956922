interface IGet {
  (key: string): any | null;
  (key: string): string | null;
  <T = any>(key: string): T | null;
}

const save = <T = any>(data: T) => ({
  to: (key: string) => {
    const strData = typeof data === 'string' ? data : JSON.stringify(data);
    localStorage.setItem(`em/${key}`, strData);
    return data;
  },
});
const get: IGet = (key: string) => {
  const data = localStorage.getItem(`em/${key}`);
  if (data === null) {
    return null;
  }
  try {
    return JSON.parse(data);
  } catch (error) {
    return data as string;
  }
};
const remove = (key: string) => localStorage.removeItem(`em/${key}`);

const ls = { save, get, remove };

export default ls;
