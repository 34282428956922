/**
 * 获取分页参数
 * @param {Object} param0 包含分页信息的对象
 * @param {number} param0.pageIndex 当前页码
 * @param {number} param0.pageSize 每页显示的条数
 * @returns {Object} 返回包含偏移量和限制数的对象
 */
export const getPageParams = ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => ({
    Offset: (pageIndex - 1) * pageSize,
    Limit: pageSize,
});