import system from '../assets/icons/system.svg';
import asset from '../assets/icons/tool/asset.svg';
import domain from '../assets/icons/tool/domain.svg';
import enterprise from '../assets/icons/tool/enterprise.svg';
import root from '../assets/icons/tool/root.svg';
import sub_domain from '../assets/icons/tool/sub_domain.svg';
import app from '../assets/icons/tool/app.svg';
import vul from '../assets/icons/tool/vul.svg';
import port from '../assets/icons/tool/port.svg';
import http from '../assets/icons/tool/http.svg';
import github from '../assets/icons/tool/github.svg';
import config from '../assets/icons/tool/config.svg';
import dark_web from '../assets/icons/tool/dark_web.svg';
import net_disk from '../assets/icons/tool/net_disk.svg';
import manage from '../assets/icons/tool/manage.svg';
import social_engineering from '../assets/icons/tool/social_engineering.svg';
// overview 目录下
import loopholes from '../assets/icons/overview/loopholes.svg';
import info_leak from '../assets/icons/overview/info_leak.svg';
import management from '../assets/icons/overview/management.svg';
import website from '../assets/icons/overview/website.svg';
import port_num from '../assets/icons/overview/port.svg';
import domain_num from '../assets/icons/overview/domain.svg';
import high_risk from '../assets/icons/overview/high_risk.svg';
import host_num from '../assets/icons/overview/host.svg';

/**
 * 扩展的自定义图标，key 为图标名称，value 为图标路径
 */
const CUSTOM_ICON_MAP = {
  system,
  em: system,
  asset: asset,
  domain: domain,
  enterprise: enterprise,
  root: root,
  sub_domain: sub_domain,
  vul: vul,
  app: app,
  wechat_applet: app,
  wechat_official_account: app,
  supply_chain: config,
  weak_password: config,
  port: port,
  http: http,
  suspicious_asset: http,
  github: github,
  config: config,
  'sensitive_info': config,
  dark_web: dark_web,
  net_disk: net_disk,
  manage: manage,
  social_engineering: social_engineering,
  loopholes: loopholes,
  host_num: host_num,
  port_num: port_num,
  domain_num: domain_num,
  high_risk: high_risk,
  info_leak: info_leak,
  website: website,
  management: management,
};

export default CUSTOM_ICON_MAP;
