import {
  Button, ButtonProps, Upload, DatePicker,
  Col, InputNumber, TimePicker, Select, CheckboxGroup, RadioGroup, Checkbox, Form, Input, message, Modal, Row, Radio
} from '@tencent/tea-component';
import React, { useState, useRef, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import moment, { Moment } from 'moment';

import getStatus from '@/utils/form';
import createJssHook from '@/utils/jss';
import { mutateCustomer } from '../services/customer';
import MutateTime from './MutateTime'
import { ICustomer } from '../types';
import { FILE_UPLOAD_URL } from '@/constants/common';

const { RangePicker } = DatePicker;

interface IProps extends ButtonProps {
  customer?: ICustomer;
  onSuccess?: () => void;
}

const { Item } = Form;
const useStyle = createJssHook({
  form: {
    '& .tea-input, & .tea-dropdown, & .tea-timepicker__input': {
      width: '100%',
    },
    '& .app-em-input, & .app-em-dropdown, & .app-em-timepicker__input': {
      width: '100%',
    },
    '& .tea-form__text,& .app-em-form__text': {
      display: 'inline-block',
      marginTop: -2,
      width: '100%'
    },
    '& .tea-inputnum .tea-input,& .app-em-inputnum .app-em-input': {
      width: 60
    },
    '& .tea-form-check,& .app-em-form-check': {
      marginRight: 13
    },
    '& .tea-form-upload__thumb,& .app-em-form-upload__thumb': {
      width: 70,
      height: 70
    },
    '& .tea-form-upload__info,& .app-em-form-upload__info': {
      height: 70
    }

  },
});
const options = [
  // {
  //   value: "每小时",
  //   text: "每小时",
  // },
  { value: "每日", text: "每日" },
  { value: "每周", text: "每周" },
  { value: "每月", text: "每月" },

];
const MutateBtn: React.FC<IProps> = ({ customer, onSuccess, ...btnProps }) => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const [cronTime, setCronTime] = useState('');
  const [toolVisible, handleToolVisible] = useState<boolean>(false);
  const [confirmVisible, setconfirmVisible] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [icon, setIcon] = useState<any>(null);
  const [image, setImage] = useState(undefined);
  const xhrRef: any = useRef(null);
  const [mode, setMode] = useState<string[]>([]);
  // const [componentsOptions, setComponentsOptions] = useState<SelectOption[]>([]);


  function handleStart(file: any, { xhr }: any) {
    setFile(file);
    xhrRef.current = xhr;
  }

  function handleIconStart(file: any, { xhr }: any) {
    getBase64(file);
    setIcon(file);
    xhrRef.current = xhr;
  }

  function handleIconSuccess(result: any) {
    form.change('Icon', result.Response.FileIdList[0])
  }

  function getBase64(file: any) {
    const reader: any = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  }

  function handleSuccess(result: any) {
    form.change('AuthFile', result.Response.FileIdList[0])
  }

  function handleError() {
    handleAbort();
    Modal.alert({
      type: "error",
      message: "错误",
      description: "请求服务器失败",
    });
  }

  function handleAbort() {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
    setFile(null);
    setIcon(null);
    form.change('AuthFile', undefined)
  }

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };
  const handleTimeDone = (value?: any) => {
    handleToolVisible(false);
    if (value !== false) {
      form.change('ScanTime', value)
    }
  };

  const showToolModal = () => {
    handleToolVisible(true);
  };

  const close = () => {
    form.change('IsScanNow', false)
    setconfirmVisible(false)
  }

  const FormPicker = () => {
    let value: any = cronTime || '每小时'
    const valueSplited = value?.split('#');
    let period = valueSplited[0] || '每小时';

    value = valueSplited[1]?.split(' ');
    if (value) {
      switch (period) {
        case '每小时':
          value = value[2] ? moment(`2020-01-01 00:${value[1]}:${value[0]}`) : undefined;
          break;
        case '每日':
          value = value[2]
            ? moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`)
            : undefined;
          break;
        case '每周':
          // 每周、每月有两个选项，用数组表示对应的两个vi
          value = [
            value[5] ? (+value[5] === 0 ? 6 : +value[5] - 1) : undefined,
            value[2] ? moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`) : undefined,
          ];
          break;
        case '每月':
          value = [
            value[3] ? +value[3] : undefined,
            value[2] ? moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`) : undefined,
          ];
          break;
        default:
          value = undefined;
      }
    }
    // console.log(1111, value)
    const commonPickerAttrs = {
      clearable: false,
      style: { width: '100%' },
    };
    switch (period) {
      case '每小时':
        return (
          <TimePicker
            {...commonPickerAttrs}
            key="每小时"
            format="HH:mm"
            defaultValue={value}
            onChange={date =>
              setCronTime(`${period}#${date?.seconds() || 0} ${date?.minutes() ||
                0} * * * *`)
            }
          />
        );
      case '每日':
        return (
          <TimePicker
            {...commonPickerAttrs}
            key="每日"
            format="HH:mm"
            defaultValue={value}
            onChange={date =>
              setCronTime(`${period}#${date?.seconds() || 0} ${date?.minutes() ||
                0} ${date?.hours() || 0} * * *`)
            }
          />
        );
      case '每周':
        return (
          <Row gap={16} key="每周">
            <Col span={10}>
              <Select
                appearance='button'
                placeholder="请选择"
                defaultValue={value?.[0]}
                options={['一', '二', '三', '四', '五', '六', '日'].map((v, k) => ({ text: v, value: k as any }))}
                onChange={(weekday: any) => {
                  const valueSplited1 = cronTime?.split?.('#')?.[1]?.split?.(' ');
                  setCronTime(`${period}#${valueSplited1[0] ||
                    0} ${valueSplited1[1] || 0} ${valueSplited1[2] || 0} * * ${weekday < 6 ? weekday + 1 : 0
                    }`)
                }}
              />
            </Col>
            <Col span={14}>
              <TimePicker
                {...commonPickerAttrs}
                defaultValue={value?.[1]}
                format="HH:mm"
                onChange={date => {
                  const currentVal = cronTime;
                  if (currentVal && date) {
                    const valueSplited2 = currentVal.split(' ');
                    setCronTime(`${period}#${date.seconds()} ${date.minutes()} ${date.hours()} * * ${valueSplited2[5]
                      }`)
                  }
                }}
              />
            </Col>
          </Row>
        );
      case '每月':
        return (
          <Row gap={16} key="每月">
            <Col span={10}>
              <Select
                appearance="button"
                placeholder="请选择"
                key="每月"
                defaultValue={value?.[0]}
                options={[...new Array(28).fill(0)].map((v, k) => ({ text: `第 ${k + 1} 日`, value: k + 1 as any }))}
                onChange={(day: any) => {
                  const currentVal = cronTime;
                  const valueSplited3 = currentVal?.split?.('#')?.[1]?.split?.(' ');
                  setCronTime(`${period}#${valueSplited3[0] ||
                    0} ${valueSplited3[1] || 0} ${valueSplited3[2] || 0} ${day} * *`)
                }}
              />
            </Col>
            <Col span={14}>
              <TimePicker
                {...commonPickerAttrs}
                defaultValue={value?.[1]}
                format="HH:mm"
                onChange={date => {
                  const currentVal = cronTime;
                  if (currentVal && date) {
                    const valueSplited4 = currentVal.split(' ');
                    setCronTime(`${period}#${date.seconds()} ${date.minutes()} ${date.hours()} ${valueSplited4[3]
                      } * *`)
                  }
                }}
              />
            </Col>
          </Row>
        );
      default:
        return null;
    }
  }

  const onSubmit = async (formData: any) => {
    const newFormData = {
      ...customer,
      ...formData,
    };
    delete newFormData.SubEnterpriseLevel;
    // delete newFormData.enableVulScanQps;
    const resp = await mutateCustomer({
      ...newFormData,
      ScanTime: JSON.stringify(newFormData.ScanTime),
      ScanCron: cronTime,
      IsIncludeFullScan: newFormData.IsIncludeFullScan === '是' ? true : false,
      ScanType: (newFormData.ScanType as any).join(','),
      AuthStartAt: newFormData.AuthTime[0].format("YYYY-MM-DD"),
      AuthEndAt: newFormData.AuthTime[1].format("YYYY-MM-DD"),
      // Qps: newFormData.enableVulScanQps ? newFormData.Qps : undefined,
      SubCompanyLevel: formData.SubEnterpriseLevel && formData.EnableScanSubEnterprise ? formData.SubCompanyLevel : undefined
    });
    if (!resp.Response?.Error) {
      message.success({
        content: customer ? '编辑成功！' : '新增成功！',
      });
      setVisible(false);
      setFile(null)
      onSuccess?.();
    }
  };
  const { form, handleSubmit, validating, submitting } = useForm<Partial<ICustomer & { AuthTime: Moment[] }>>({
    onSubmit,
    initialValuesEqual: () => true,
    initialValues: customer
      ? {
        ...customer,
      }
      : {
        Qps: 50
      },
    validate: formData => {
      return ({
        Name: !formData.Name ? '请输入企业名称' : undefined,
        ScanTime: !formData.ScanTime ? '请选择测绘时间' : undefined,
        ScanCron: cronTime && (!cronTime.split('#')[0] || !cronTime.split('#')[1] || cronTime.includes('undefined')) ? '请选择周期测绘时间' : undefined,
        ScanType: !formData.ScanType ? '请选择测绘功能' : undefined,
        AuthTime: !formData.AuthTime ? '请选择时间' : undefined,
        // Keywords: !formData.Keywords ? '请输入关键词' : undefined,
        IsIncludeFullScan: !formData.IsIncludeFullScan ? '请选择' : undefined,
        AuthFile: (formData.EnableAuth && !formData.AuthFile) ? '请选择文件' : undefined,
        // Domains: !formData.Domains ? '请输入域名' : undefined,
        // ScanDirLevel: !formData.ScanDirLevel ? '请选择层级' : undefined,
        // Qps: !formData.Qps ? '请填写10～500内的QPS' : undefined,
        // Components: !formData.Components?.length ? '请至少选择一个' : undefined,
        // Icon: !formData.Icon ? '请选择!' : undefined,
      })
    },
  });
  const nameField = useField('Name', form);
  const authTime = useField('AuthTime', form);
  const percentField = useField('Percent', form);
  const LevelField = useField('SubCompanyLevel', form);
  const scanCron = useField('ScanCron', form);
  const EnableScanSubEnterprise = useField('EnableScanSubEnterprise', form);
  const SubEnterpriseLevel = useField('SubEnterpriseLevel', form);
  const enableCron = useField('EnableCron', form);
  const scanTypeField = useField('ScanType', form);
  const isIncludeFullScan = useField('IsIncludeFullScan', form);
  const fileIdField = useField('Icon', form);
  const scanTime = useField('ScanTime', form);
  const isScanNow = useField('IsScanNow', form);
  const enableAuth = useField('EnableAuth', form);
  const authFile = useField('AuthFile', form);
  const keywordsField = useField('Keywords', form);
  const Qps = useField('Qps', form);
  // const enableVulScanQps = useField('enableVulScanQps', form);
  // const Domains = useField('Domains', form);
  // const ScanDirLevel = useField('ScanDirLevel', form);
  // const Components = useField('Components', form);

  const onClose = () => {
    // Qps: 100, Components: [], ScanDirLevel: 'level2', Domains: '全部' 
    form?.restart({});
    form.reset()
    setFile('')
    setIcon('')
    setCronTime('')
  };

  const onCancle = () => setVisible(false)

  const onOk = async () => {
    if (mode.length !== 3) {
      message.error({
        content: "请先确认完成以下事项",
      })
      return
    }
    setconfirmVisible(false)
  }

  useEffect(() => {
    if (visible && customer) {
      form.initialize({
        ...customer,
        SubEnterpriseLevel: customer.SubCompanyLevel > 0,
        ScanTime: JSON.parse(customer.ScanTime),
        IsIncludeFullScan: customer.IsIncludeFullScan ? '是' : '否',
        // enableVulScanQps: true,
        ScanType: (customer.ScanType as unknown as string)?.split(',') as any,
        AuthTime: customer.AuthStartAt ? [moment(customer.AuthStartAt), moment(customer.AuthEndAt)] : undefined
      });
      setCronTime(customer.ScanCron)
    } else if (!visible) {
      onClose()
    } else {
      form.initialize({
        Qps: 50,
        ScanType: ['资产收集'],
      });
      // searchComponents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // const searchComponents = async () => {
  //   const resp = await queryComponentsList({
  //     Limit: 99999,
  //   });
  //   if (!resp.Response?.Error) {
  //     const optionsList = resp.Response?.List.map(item => {
  //       return {
  //         value: String(item.Id),
  //         text: item.Name,
  //       };
  //     });
  //     setComponentsOptions(optionsList || []);
  //   }
  // };

  useEffect(() => {
    if (isScanNow.input.value && window.location.href.includes('woa.com')) {
      setconfirmVisible(true)
      // form.change('IsScanNow', true)
    } else {
      setMode([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanNow.input.value]);

  useEffect(() => {
    if (SubEnterpriseLevel.input.value && customer?.SubCompanyLevel) {
      form.change('SubCompanyLevel', customer?.SubCompanyLevel > 0 ? customer?.SubCompanyLevel : 0)
    } else if (!SubEnterpriseLevel.input.value) {
      form.change('SubCompanyLevel', 0)
    } else {
      form.change('SubCompanyLevel', 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SubEnterpriseLevel.input.value && visible]);
  return (
    <>
      <Button {...btnProps} onClick={onClick} />
      <Modal visible={visible} caption={customer ? '编辑企业' : '新建企业'} onClose={onCancle} >
        <form onSubmit={handleSubmit} >
          <Modal.Body
          // style={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            <Form className={classes.form} layout='fixed' fixedLabelWidth={100} >
              <Item label='企业图标'>
                <Upload
                  {...fileIdField.input}
                  withCredentials
                  name='File'
                  action={FILE_UPLOAD_URL}
                  accept=".png, .jpg, .jpeg"
                  onStart={handleIconStart}
                  onSuccess={handleIconSuccess}
                  onError={handleError}
                >
                  {({ open }) => (
                    <Upload.Image
                      filename={icon && icon.name}
                      image={
                        icon
                          ? image
                          : "//imgcache.qq.com/open_proj/proj_qcloud_v2/tea-style/dist/assets/image/upload-thumb.png"
                      }
                      button={
                        getStatus(fileIdField.meta, validating) === "validating" ? (
                          <Button htmlType='button' onClick={handleAbort}>取消上传</Button>
                        ) : (
                          <>
                            <Button htmlType='button' onClick={open}>
                              选择图片
                            </Button>
                          </>
                        )
                      }
                    />
                  )}
                </Upload>
              </Item>
              <Item
                required
                label='企业名称'
                status={getStatus(nameField.meta, validating)}
                message={getStatus(nameField.meta, validating) === 'error' && nameField.meta.error}
              >
                <Input
                  {...nameField.input}
                  placeholder='请输入标准企业名称，如:腾讯云计算(北京)有限责任公司'
                />
              </Item>
              <Item
                required
                align='top'
                label='自动挖掘该企业名称所有资产'
                tips="如只想扫描特定的某几个域名，则建议勾选否"
                status={getStatus(isIncludeFullScan.meta, validating)}
                message={getStatus(isIncludeFullScan.meta, validating) === 'error' && isIncludeFullScan.meta.error}
              >
                <RadioGroup  {...isIncludeFullScan.input}>
                  <Radio name={'是'}>是</Radio>
                  <Radio name={'否'}>否</Radio>
                </RadioGroup>
              </Item>
              <Item
                // required
                label='关键词'
              // status={getStatus(keywordsField.meta, validating)}
              // message={getStatus(keywordsField.meta, validating) === 'error' && keywordsField.meta.error}
              >
                <Input
                  {...keywordsField.input}
                  placeholder='为保证测绘准确，请输入企业关键词'
                />
              </Item>
              <Item
                required
                label='授权扫描时间'
                status={getStatus(authTime.meta, validating)}
                message={getStatus(authTime.meta, validating) === 'error' && authTime.meta.error}
              >
                <RangePicker
                  {...authTime.input}
                />
              </Item>
              {
                !customer && <>
                  <Item label='已经授权'>
                    <Checkbox {...enableAuth.input} name="已经授权"></Checkbox>
                  </Item>
                  {
                    enableAuth.input.value && <Item
                      label='文件'
                      required
                      status={getStatus(authFile.meta, validating)}
                      message={getStatus(authFile.meta, validating) === 'error' && authFile.meta.error}
                    >
                      <Upload
                        {...authFile.input}
                        withCredentials
                        action={FILE_UPLOAD_URL}
                        name='File'
                        accept="image/*,.pdf,.docx,.doc,.csv,.xls,.xlsx,.zip,.rar,.xml,.txt,.tar,.gz,.7z,.bzip2,.gzip,.wim,.xz,.nessus,.xmind"
                        onStart={handleStart}
                        onSuccess={handleSuccess}
                        onError={handleError}
                      >
                        {({ open }) => (
                          <Upload.File
                            filename={file && file.name}
                            button={
                              getStatus(authFile.meta, validating) === "validating" ? (
                                <Button htmlType='button' onClick={handleAbort}>取消上传</Button>
                              ) : (
                                <>
                                  <Button htmlType='button' onClick={open}>
                                    {"选择文件"}
                                  </Button>
                                  {authFile.input.value && (
                                    <Button
                                      type="link"
                                      htmlType='button'
                                      style={{ marginLeft: 8 }}
                                      onClick={handleAbort}
                                    >
                                      删除
                                    </Button>
                                  )}
                                </>
                              )
                            }
                          />
                        )}
                      </Upload>
                    </Item>
                  }
                </>
              }

            </Form>
            <hr />
            <Form className={classes.form} layout='fixed' fixedLabelWidth={100}>
              <Item
                label='关注子公司'
                align='middle'
              >
                <Checkbox {...EnableScanSubEnterprise.input} />
                <InputNumber
                  {...percentField.input}
                  min={0}
                  max={100}
                  step={1}
                  formatter={value => `${value}%`}
                  parser={value => Number(value.replace("%", ""))}
                />
              </Item>
              {
                EnableScanSubEnterprise.input.value && <Item
                  label='限制子公司层级'
                  align='middle'
                >
                  <Checkbox
                    {...SubEnterpriseLevel.input} />
                  <InputNumber
                    {...LevelField.input}
                    min={1}
                    step={1}
                  />
                </Item>
              }
              <Item
                required
                label='测绘功能'
                status={getStatus(scanTypeField.meta, validating)}
                message={getStatus(scanTypeField.meta, validating) === 'error' && scanTypeField.meta.error}
              >
                <CheckboxGroup {...scanTypeField.input}>
                  <Checkbox name="资产收集" disabled>资产收集</Checkbox>
                  <Checkbox name="漏洞扫描">漏洞扫描</Checkbox>
                </CheckboxGroup>
              </Item>
              {
                scanTypeField.input.value.includes('漏洞扫描') && <Item
                  label='漏洞扫描QPS'
                  align='middle'
                >
                  {/* <Checkbox {...enableVulScanQps.input} /> */}
                  <InputNumber
                    {...Qps.input}
                    min={30}
                    max={150}
                    step={1}
                  />
                </Item>
              }
              <Item
                required
                align='top'
                label='允许扫描时间'
                status={getStatus(scanTime.meta, validating)}
                message={getStatus(scanTime.meta, validating) === 'error' && scanTime.meta.error}
              >
                {/* <Form.Text > */}
                <Button
                  {...scanTime.input}
                  type='link'
                  htmlType='button'
                  onClick={(e) => {
                    showToolModal();
                  }}
                >
                  设置
                </Button>
                {/* </Form.Text> */}
              </Item>
              <Item
                label="测绘周期"
                align='middle'
                status={getStatus(scanCron.meta, validating)}
                message={getStatus(scanCron.meta, validating) === 'error' && scanCron.meta.error}
              >
                <Form.Text>
                  <Row gap={16} verticalAlign='middle'>
                    <Col span={2}>
                      <Checkbox {...enableCron.input} />
                    </Col>
                    <Col span={8}>
                      <Select
                        defaultValue={cronTime?.toString()?.split('#')?.[0]}
                        appearance="button"
                        options={options}
                        onChange={(period: string) =>
                          setCronTime(`${period}#`)
                        }
                      />
                    </Col>
                    <Col span={14}>
                      <FormPicker />
                    </Col>
                  </Row>
                </Form.Text>
              </Item>
              <Item label='立即启动'>
                <Checkbox {...isScanNow.input} name="立即启动" ></Checkbox>
              </Item>
            </Form>
            {/* <hr />
            <Form.Title>扫描配置</Form.Title>
            <Form layout='default' hideLabel={false} className={classes.form}>
              <Form.Item
                required
                label='QPS设置'
                message='高QPS对业务有一定影响，建议值为100'
                status={getStatus(Qps.meta, validating)}
              >
                <InputNumber
                  {...Qps.input}
                  min={10} max={500} />
              </Form.Item>
              <Form.Item
                required
                label='指定组件'
                status={getStatus(Components.meta, validating)}>
                <SelectMultiple
                  {...Components.input}
                  style={{ width: '100%' }}
                  appearance='button'
                  options={componentsOptions}
                  placeholder='请选择组件'
                  listWidth={410}
                />
              </Form.Item>
              <Form.Item
                required
                label='指定域名'
                message='多域名请用,隔开'
                status={getStatus(Domains.meta, validating)}
              >
                <Input
                  {...Domains.input}
                  placeholder='请输入域名'
                />
              </Form.Item>
              <Form.Item
                required
                label='扫描目录层级'
                status={getStatus(ScanDirLevel.meta, validating)}
              >
                <Select
                  {...ScanDirLevel.input}
                  style={{ width: '100%' }}
                  appearance='button'
                  options={[{
                    value: 'level1',
                    text: "1级",
                  },
                  {
                    value: 'level2',
                    text: "2级",
                  },
                  {
                    value: 'level3',
                    text: "3级",
                  },
                  {
                    value: 'level4',
                    text: "4级",
                  }]}
                />
              </Form.Item>
            </Form> */}
          </Modal.Body>
          <Modal.Footer>
            <Button type='primary' loading={submitting} disabled={submitting}>
              确定
            </Button>
            <Button type="weak" htmlType='button' onClick={onCancle}>
              取消
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <MutateTime
        visible={toolVisible}
        initValue={scanTime.input.value}
        onDone={handleTimeDone}
      />
      <Modal
        disableEscape
        visible={confirmVisible}
        caption="启动测绘前，请先确认已经完成以下事项："
        onClose={close}
      >
        <Modal.Body>
          <Checkbox.Group
            value={mode}
            onChange={value => setMode(value)}
            layout="column"
          >
            <Checkbox name="1" >
              已取得客户允许扫描的书面授权
            </Checkbox>
            <Checkbox name="2" >
              已在客户群里同步扫描注意事项，并得到了客户同意扫描的回复
            </Checkbox>
            <Checkbox name="3">
              知晓未经客户授权和同意，擅自启动测绘将触犯<span style={{ color: 'red' }}>公司高压线</span>
            </Checkbox>
          </Checkbox.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" onClick={onOk}>
            确定
          </Button>
          <Button type="weak" onClick={close}>
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MutateBtn;
